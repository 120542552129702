import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../pages/admin/DashboardPage';
import AdminDetailPage from '../pages/admin/AdminAllTeacherPage';
import AdminDetailEditPage from '../pages/admin/adminallstudentstatus';
import Application from '../pages/admin/application';

const AdminRoutes = () => ( 
        <Routes>
            <Route path="/admin-dashboard" element={<DashboardPage />} />
            <Route path="/admin-detail" element={<AdminDetailPage />} />
            <Route path="/admin-detail-edit" element={<AdminDetailEditPage />} />
            <Route path="/admin-application" element={<Application />} />
        </Routes> 
);

export default AdminRoutes;
