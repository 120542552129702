import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { getAuthToken } from '../../auth_token/authUtils';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Admin from './usertable/admin';
import Teacher from './usertable/teacher';
import Student from './usertable/student';
import './css/dashboard.css';
import Baseurl from '../../baseurl/baserurl';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

export default function CustomizedTables() {
  const [open, setOpen] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [school_Name, setSchoolName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [role, setRole] = React.useState('');
  const [mobile, setSchoolContact] = React.useState('');
  const [schools, setSchools] = React.useState([]);
  const [selectedSchool, setSelectedSchool] = React.useState(null);

  React.useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    const user = JSON.parse(localStorage.getItem('user'));
    const school_id = user?.school_id;

    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewTeacher/show_teachers`, {
        method: 'POST', // Changed from GET to POST
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_id: school_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setSchools(data);
      } else {
        const error = await response.json();
        alert('Error fetching schools: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEditModal(false); // Close the edit modal as well
  };

  const handleSubmit = async () => {
    const token = getAuthToken();
    const school_id = JSON.parse(localStorage.getItem('user')).school_id;
    const name = JSON.parse(localStorage.getItem('user')).school_name;
    const school_contact = JSON.parse(localStorage.getItem('user')).contact;

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewTeacher/teachers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_Name: school_Name,
          email: email,
          password: password,
          mobile: mobile,
          role: role,
          school_id: school_id,
          name: name,
          school_contact: school_contact,
        }),
      });

      if (response.ok) {
        fetchSchools();
        setSchoolName('');
        setEmail('');
        setPassword('');
        setSchoolContact('');
        setRole('');
        handleClose();
      } else {
        const error = await response.json();
        alert('Error adding school: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditOpen = (school) => {
    setSelectedSchool(school);
    setSchoolName(school.name);
    setEmail(school.email);
    setPassword(school.password);
    setSchoolContact(school.mobile);
    setRole(school.role);
    setOpenEditModal(true);
  };


  const schoolname = JSON.parse(localStorage.getItem('user')).school_name;

  const handleEditSubmit = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewTeacher/teachers/${selectedSchool.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_Name: schoolname,
          email: email,
          password: password,
          mobile: mobile,
          role: role,
        }),
      });

      if (response.ok) {
        fetchSchools(); // Refresh the table after editing
        handleClose();
      } else {
        const error = await response.json();
        alert('Error updating school: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewTeacher/teachers/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        fetchSchools(); // Refresh the list after deletion
      } else {
        alert('Error deleting school.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add New Teacher
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Teacher</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="schoolName"
            label="Teacher Name"
            type="text"
            fullWidth
            value={school_Name}
            onChange={(e) => setSchoolName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="schoolContact"
            label="Teacher Contact"
            type="text"
            fullWidth
            value={mobile}
            onChange={(e) => setSchoolContact(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              {/* <MenuItem value="student">Student</MenuItem> */}
              <MenuItem value="teacher">Teacher</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditModal} onClose={handleClose}>
        <DialogTitle sx={{ color: 'black', textAlign: 'center' }}>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="schoolName"
            label="Name"
            type="text"
            fullWidth
            value={school_Name}
            onChange={(e) => setSchoolName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="schoolContact"
            label="Teacher Contact"
            type="text"
            fullWidth
            value={mobile}
            onChange={(e) => setSchoolContact(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              {/* <MenuItem value="admin">Admin</MenuItem> */}
              {/* <MenuItem value="student">Student</MenuItem> */}
              <MenuItem value="teacher">Teacher</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleEditSubmit} color="primary">Update</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography className="attendance-header" sx={{ color: 'white' }}>
            You
          </Typography>
          <Item>
            <Admin />
          </Item>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography className="attendance-header" sx={{ color: 'white' }}>
            Your Teachers
          </Typography>
          <Item>
            <Teacher />
          </Item>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography className="attendance-header" sx={{ color: 'white' }}>
            Your Studends
          </Typography>
          <Item>
            <Student />
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

