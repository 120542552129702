import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography, Snackbar, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from '../../auth_token/authUtils';
import StudentChart from './Dashboard/barChart';
import './css/dashboard.css';
import StudentOfTheMonth from './Dashboard/studentofthemonth';
import StudentOfTheYear from './Dashboard/studentoftheyear';
import Baseurl from '../../baseurl/baserurl';

const Item = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '15px',
  textAlign: 'center',
  transition: 'transform 0.3s',
}));

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default function BasicGrid() {
  const [present, setPresent] = useState(0);
  const [absent, setAbsent] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');
  const alertTimeoutRef = useRef(null);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;
        const teacher_id = user?.teacher_id;

        if (!school_id || !teacher_id) {
          setAlertMessage('School ID or Teacher ID is missing.');
          setAlertType('error');
          setAlertOpen(true);
          navigate('/');
          return;
        }

        const token = getAuthToken();
        if (!token) {
          setAlertMessage('Sorry, you are not authenticated. Please re-login.');
          setAlertType('error');
          setAlertOpen(true);
          navigate('/');
          return;
        }

        const todayDate = getTodayDate();

        const response = await fetch(`${Baseurl.baseURL}api/attendance/allAttendence`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            school_id,
            teacher_id,
            date: todayDate,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && Array.isArray(data.data)) {
            const attendanceData = data.data;

            const presentCount = attendanceData.filter(item => item.status === 'Present').length;
            const absentCount = attendanceData.filter(item => item.status === 'Absent').length;
            const totalCount = attendanceData.length;

            setPresent(presentCount);
            setAbsent(absentCount);
            setTotal(totalCount);
          } else {
            setAlertMessage('No data found or data is in an unexpected format.');
            setAlertType('warning');
            setAlertOpen(true);
          }
        } else {
          const error = await response.json();
          setAlertMessage('Error fetching attendance data: ' + error.message);
          setAlertType('error');
          setAlertOpen(true);
        }
      } catch (error) {
        setAlertMessage('An error occurred. Please try again.');
        setAlertType('error');
        setAlertOpen(true);
      }
    };

    fetchAttendanceData();

    const intervalId = setInterval(fetchAttendanceData, 50000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  useEffect(() => {
    if (alertOpen) {
      if (alertTimeoutRef.current) {
        clearTimeout(alertTimeoutRef.current);
      }
      alertTimeoutRef.current = setTimeout(() => {
        setAlertOpen(false);
      }, 6000); // Close the alert after 6 seconds
    }
  }, [alertOpen]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box className="dashboard-container">
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          <AlertTitle>{alertType === 'error' ? 'Error' : alertType === 'warning' ? 'Warning' : 'Success'}</AlertTitle>
          {alertMessage}
        </Alert>
      </Snackbar>
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Daily Attendance
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Today Present Students
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>{`${present}/${total}`}</Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Today Absent Students
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>{`${absent}/${total}`}</Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Total Students
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>{`${total}`}</Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={12} className="chart-container">
              <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
                Monthly Attendance Chart
              </Typography>
              <Item className="dashboard-card">
                <StudentChart />
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Student Of the Month
          </Typography>
          <Item className="dashboard-card">
            <StudentOfTheMonth />
          </Item>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Student Of the Year
          </Typography>
          <Item className="dashboard-card">
            <StudentOfTheYear />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
