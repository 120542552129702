

// import * as React from 'react';
// import { useState, useEffect, useRef } from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { Card, CardContent, Typography, Snackbar, Alert, AlertTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { getAuthToken } from '../../../auth_token/authUtils';
// import './css/dashboard.css';
// import StudentChart from './Dashboard/barChart';
// import StudentOfTheMonth from './Dashboard/studentofthemonth';
// import StudentOfTheYear from './Dashboard/studentoftheyear';
// import LineGraph from './Dashboard/lineprogressgraph';
// import Baseurl from '../../../baseurl/baserurl';

// const Item = styled(Card)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderRadius: '15px',
//   textAlign: 'center',
//   transition: 'transform 0.3s',
//   // backgroundColor: '#333', // Black theme
//   color: '#fff', // White text for contrast
// }));

// const TableHeaderCell = styled(TableCell)(({ theme }) => ({
//   color: '#fff',
//   backgroundColor: '#555',
// }));

// const TableBodyCell = styled(TableCell)(({ theme }) => ({
//   color: '#fff',
//   backgroundColor: '#333',
// }));

// const getTodayDate = () => {
//   const today = new Date();
//   const year = today.getFullYear();
//   const month = String(today.getMonth() + 1).padStart(2, '0');
//   const day = String(today.getDate()).padStart(2, '0');
//   return `${year}-${month}-${day}`;
// };

// export default function BasicGrid() {
//   const [present, setPresent] = useState(0);
//   const [absent, setAbsent] = useState(0);
//   const [total, setTotal] = useState(0);
//   const [attendanceData, setAttendanceData] = useState([]);
//   const navigate = useNavigate();
//   const [alertOpen, setAlertOpen] = useState(false);
//   const [alertType, setAlertType] = useState('error');
//   const [alertMessage, setAlertMessage] = useState('');
//   const alertTimeoutRef = useRef(null);

//   useEffect(() => {
//     const fetchAttendanceData = async () => {
//       try {
//         const user = JSON.parse(localStorage.getItem('user'));
//         const school_id = user?.school_id;
//         const student_id = user?.student_id;

//         if (!school_id) {
//           setAlertMessage('School ID or Teacher ID is missing.');
//           setAlertType('error');
//           setAlertOpen(true);
//           navigate('/');
//           return;
//         }

//         const token = getAuthToken();
//         if (!token) {
//           setAlertMessage('Sorry, you are not authenticated. Please re-login.');
//           setAlertType('error');
//           setAlertOpen(true);
//           navigate('/');
//           return;
//         }

//         const todayDate = getTodayDate();

//         const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/specificAttendence`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             school_id,
//             student_id,
//             // date: todayDate,
//           }),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           if (data && data.data && Array.isArray(data.data)) {
//             const sortedData = data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
//             setAttendanceData(sortedData);

//             const presentCount = data.data.filter(item => item.status === 'Present').length;
//             const absentCount = data.data.filter(item => item.status === 'Absent').length;
//             const totalCount = data.data.length;

//             setPresent(presentCount);
//             setAbsent(absentCount);
//             setTotal(totalCount);
//           } else {
//             setAlertMessage('No data found or data is in an unexpected format.');
//             setAlertType('warning');
//             setAlertOpen(true);
//           }
//         } else {
//           const error = await response.json();
//           setAlertMessage('Error fetching attendance data: ' + error.message);
//           setAlertType('error');
//           setAlertOpen(true);
//         }
//       } catch (error) {
//         setAlertMessage('An error occurred. Please try again.');
//         setAlertType('error');
//         setAlertOpen(true);
//       }
//     };

//     fetchAttendanceData();

//     const intervalId = setInterval(fetchAttendanceData, 50000);

//     return () => clearInterval(intervalId);
//   }, [navigate]);

//   useEffect(() => {
//     if (alertOpen) {
//       if (alertTimeoutRef.current) {
//         clearTimeout(alertTimeoutRef.current);
//       }
//       alertTimeoutRef.current = setTimeout(() => {
//         setAlertOpen(false);
//       }, 6000); // Close the alert after 6 seconds
//     }
//   }, [alertOpen]);

//   const handleAlertClose = () => {
//     setAlertOpen(false);
//   };

//   return (
//     <Box className="dashboard-container">
//       <Snackbar
//         open={alertOpen}
//         autoHideDuration={6000}
//         onClose={handleAlertClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//       >
//         <Alert onClose={handleAlertClose} severity={alertType}>
//           <AlertTitle>{alertType === 'error' ? 'Error' : alertType === 'warning' ? 'Warning' : 'Success'}</AlertTitle>
//           {alertMessage}
//         </Alert>
//       </Snackbar>

//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={12} md={12} lg={12}>
//           <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//             Attendance Records
//           </Typography>
//           <TableContainer component={Paper} style={{ backgroundColor: '#333', color: '#fff' }}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableHeaderCell>ID</TableHeaderCell>
//                   <TableHeaderCell>Student Name</TableHeaderCell>
//                   <TableHeaderCell>Email</TableHeaderCell>
//                   <TableHeaderCell>Status</TableHeaderCell>
//                   <TableHeaderCell>Homework</TableHeaderCell>
//                   <TableHeaderCell>Date</TableHeaderCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {attendanceData.map((row) => (
//                   <TableRow key={row.id}>
//                     <TableBodyCell>{row.id}</TableBodyCell>
//                     <TableBodyCell>{row.student_name}</TableBodyCell>
//                     <TableBodyCell>{row.student_email}</TableBodyCell>
//                     <TableBodyCell>{row.status}</TableBodyCell>
//                     <TableBodyCell>{row.homework}</TableBodyCell>
//                     <TableBodyCell>{row.date}</TableBodyCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Grid>
//         <Grid item xs={12} sm={12} md={12} lg={12}>
//           <Grid item xs={12} md={12} className="chart-container">
//             <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//               Monthly Progress Line Graph
//             </Typography>
//             <Item className="dashboard-card">
//               <LineGraph />
//             </Item>
//           </Grid>
//           {/* <Grid item xs={12} md={12} className="chart-container">
// <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//   Monthly Attendance Chart
// </Typography>
// <Item className="dashboard-card">
//   <StudentChart />
// </Item>
//           </Grid> */}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }





// import * as React from 'react';
// import { useState, useEffect, useRef } from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { Card, Typography, Snackbar, Alert, AlertTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { getAuthToken } from '../../../auth_token/authUtils';
// import './css/dashboard.css';
// import LineGraph from './Dashboard/lineprogressgraph';
// import Baseurl from '../../../baseurl/baserurl';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css'; // Import default styles for Calendar
// import StudentChart from './Dashboard/barChart';

// // Styled components
// const Item = styled(Card)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderRadius: '15px',
//   textAlign: 'center',
//   transition: 'transform 0.3s',
//   color: '#fff', // White text for contrast
// }));

// const TableHeaderCell = styled(TableCell)(({ theme }) => ({
//   color: '#fff',
//   backgroundColor: '#555',
// }));

// const TableBodyCell = styled(TableCell)(({ theme }) => ({
//   color: '#fff',
//   backgroundColor: '#333',
// }));

// const getTodayDate = () => {
//   const today = new Date();
//   const year = today.getFullYear();
//   const month = String(today.getMonth() + 1).padStart(2, '0');
//   const day = String(today.getDate()).padStart(2, '0');
//   return `${year}-${month}-${day}`;
// };

// export default function BasicGrid() {
//   const [present, setPresent] = useState(0);
//   const [absent, setAbsent] = useState(0);
//   const [total, setTotal] = useState(0);
//   const [attendanceData, setAttendanceData] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const navigate = useNavigate();
//   const [alertOpen, setAlertOpen] = useState(false);
//   const [alertType, setAlertType] = useState('error');
//   const [alertMessage, setAlertMessage] = useState('');
//   const alertTimeoutRef = useRef(null);

//   useEffect(() => {
//     const fetchAttendanceData = async () => {
//       try {
//         const user = JSON.parse(localStorage.getItem('user'));
//         const school_id = user?.school_id;
//         const student_id = user?.student_id;

//         if (!school_id) {
//           setAlertMessage('School ID or Teacher ID is missing.');
//           setAlertType('error');
//           setAlertOpen(true);
//           navigate('/');
//           return;
//         }

//         const token = getAuthToken();
//         if (!token) {
//           setAlertMessage('Sorry, you are not authenticated. Please re-login.');
//           setAlertType('error');
//           setAlertOpen(true);
//           navigate('/');
//           return;
//         }

//         const todayDate = getTodayDate();

//         const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/specificAttendence`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             school_id,
//             student_id,
//           }),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           if (data && data.data && Array.isArray(data.data)) {
//             const sortedData = data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
//             setAttendanceData(sortedData);

//             const presentCount = data.data.filter(item => item.status === 'Present').length;
//             const absentCount = data.data.filter(item => item.status === 'Absent').length;
//             const totalCount = data.data.length;

//             setPresent(presentCount);
//             setAbsent(absentCount);
//             setTotal(totalCount);
//           } else {
//             setAlertMessage('No data found or data is in an unexpected format.');
//             setAlertType('warning');
//             setAlertOpen(true);
//           }
//         } else {
//           const error = await response.json();
//           setAlertMessage('Error fetching attendance data: ' + error.message);
//           setAlertType('error');
//           setAlertOpen(true);
//         }
//       } catch (error) {
//         setAlertMessage('An error occurred. Please try again.');
//         setAlertType('error');
//         setAlertOpen(true);
//       }
//     };

//     fetchAttendanceData();

//     const intervalId = setInterval(fetchAttendanceData, 50000);

//     return () => clearInterval(intervalId);
//   }, [navigate]);

//   useEffect(() => {
//     if (alertOpen) {
//       if (alertTimeoutRef.current) {
//         clearTimeout(alertTimeoutRef.current);
//       }
//       alertTimeoutRef.current = setTimeout(() => {
//         setAlertOpen(false);
//       }, 6000); // Close the alert after 6 seconds
//     }
//   }, [alertOpen]);

//   const handleAlertClose = () => {
//     setAlertOpen(false);
//   };

//   // Format attendance data for calendar
//   const formatAttendanceForCalendar = () => {
//     const formatted = {};
//     attendanceData.forEach(item => {
//       const date = new Date(item.date);
//       const formattedDate = date.toISOString().split('T')[0]; // Format to yyyy-mm-dd
//       formatted[formattedDate] = item.status;
//     });
//     return formatted;
//   };

//   // Handle date selection in calendar
//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   // Get status for the selected date
//   const getStatusForDate = (date) => {
//     const formattedDate = new Date(date).toISOString().split('T')[0];
//     return formatAttendanceForCalendar()[formattedDate] || null;
//   };

//   // Apply styles based on attendance status
//   // const tileClassName = ({ date, view }) => {
//   //   if (view === 'month') {
//   //     const status = getStatusForDate(date);
//   //     if (status === 'Present') {
//   //       return 'present';
//   //     } else if (status === 'Absent') {
//   //       return 'absent';
//   //     }
//   //   }
//   //   return '';
//   // };

//   const tileClassName = ({ date, view }) => {
//     if (view === 'month') {
//       // Convert date to UTC and format as yyyy-mm-dd
//       const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().split('T')[0];
//       const status = formatAttendanceForCalendar()[formattedDate];

//       if (status === 'Present') {
//         return 'present';
//       } else if (status === 'Absent') {
//         return 'absent';
//       }
//     }
//     return '';
//   };


//   return (
//     <Box className="dashboard-container">
//       <Snackbar
//         open={alertOpen}
//         autoHideDuration={6000}
//         onClose={handleAlertClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//       >
//         <Alert onClose={handleAlertClose} severity={alertType}>
//           <AlertTitle>{alertType === 'error' ? 'Error' : alertType === 'warning' ? 'Warning' : 'Success'}</AlertTitle>
//           {alertMessage}
//         </Alert>
//       </Snackbar>

//       <Grid container spacing={3}>
//         <Grid item xs={12} md={6}>
//           <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//             Attendance Records
//           </Typography>
//           <TableContainer component={Paper} style={{ backgroundColor: '#333', color: '#fff', maxHeight: 400, overflowY: 'auto' }}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableHeaderCell>ID</TableHeaderCell>
//                   <TableHeaderCell>Student Name</TableHeaderCell>
//                   <TableHeaderCell>Email</TableHeaderCell>
//                   <TableHeaderCell>Status</TableHeaderCell>
//                   <TableHeaderCell>Homework</TableHeaderCell>
//                   <TableHeaderCell>Date</TableHeaderCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {attendanceData.map((row) => (
//                   <TableRow key={row.id}>
//                     <TableBodyCell>{row.id}</TableBodyCell>
//                     <TableBodyCell>{row.student_name}</TableBodyCell>
//                     <TableBodyCell>{row.student_email}</TableBodyCell>
//                     <TableBodyCell>{row.status}</TableBodyCell>
//                     <TableBodyCell>{row.homework}</TableBodyCell>
//                     <TableBodyCell>{new Date(row.date).toLocaleDateString()}</TableBodyCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <Grid item xs={12} md={12} className="chart-container">
//             <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//               Attendence On Calender
//             </Typography>
//             <Item className="dashboard-card">
//               <Calendar
//                 onChange={handleDateChange}
//                 value={selectedDate}
//                 tileClassName={tileClassName}
//               />
//             </Item>
//           </Grid>
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <Grid item xs={12} md={12} className="chart-container">
//             <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//               Home Work On Calender
//             </Typography>
//             <Item className="dashboard-card">
//               <Calendar
//                 onChange={handleDateChange}
//                 value={selectedDate}
//                 tileClassName={tileClassName}
//               />
//             </Item>
//           </Grid>
//         </Grid>
//         <Grid item xs={12} md={12} className="chart-container">
//           <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//             Monthly Progress Line Graph
//           </Typography>
//           <Item className="dashboard-card">
//             <LineGraph />
//           </Item>
//         </Grid>

//         {/* <Grid item xs={12} md={6} className="chart-container">
//             <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
//               Monthly Attendance Bar
//             </Typography>
//             <Item className="dashboard-card">
//               <StudentChart />
//             </Item>
//           </Grid> */}
//       </Grid>
//     </Box>
//   );
// }



import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Card, Typography, Snackbar, Alert, AlertTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from '../../../auth_token/authUtils';
import './css/dashboard.css';
import LineGraph from './Dashboard/lineprogressgraph';
import Baseurl from '../../../baseurl/baserurl';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import default styles for Calendar

// Styled components
const Item = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '15px',
  textAlign: 'center',
  transition: 'transform 0.3s',
  color: '#fff',
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#555',
}));

const TableBodyCell = styled(TableCell)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#333',
}));

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default function BasicGrid() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');
  const alertTimeoutRef = useRef(null);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;
        const student_id = user?.student_id;

        if (!school_id) {
          setAlertMessage('School ID or Teacher ID is missing.');
          setAlertType('error');
          setAlertOpen(true);
          navigate('/');
          return;
        }

        const token = getAuthToken();
        if (!token) {
          setAlertMessage('Sorry, you are not authenticated. Please re-login.');
          setAlertType('error');
          setAlertOpen(true);
          navigate('/');
          return;
        }

        const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/specificAttendence`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            school_id,
            student_id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && Array.isArray(data.data)) {
            const sortedData = data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            setAttendanceData(sortedData);
          } else {
            setAlertMessage('No data found or data is in an unexpected format.');
            setAlertType('warning');
            setAlertOpen(true);
          }
        } else {
          const error = await response.json();
          setAlertMessage('Error fetching attendance data: ' + error.message);
          setAlertType('error');
          setAlertOpen(true);
        }
      } catch (error) {
        setAlertMessage('An error occurred. Please try again.');
        setAlertType('error');
        setAlertOpen(true);
      }
    };

    fetchAttendanceData();

    const intervalId = setInterval(fetchAttendanceData, 50000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  useEffect(() => {
    if (alertOpen) {
      if (alertTimeoutRef.current) {
        clearTimeout(alertTimeoutRef.current);
      }
      alertTimeoutRef.current = setTimeout(() => {
        setAlertOpen(false);
      }, 6000); // Close the alert after 6 seconds
    }
  }, [alertOpen]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // Format attendance data for calendar (both attendance and homework)
  const formatAttendanceForCalendar = () => {
    const formatted = {};
    attendanceData.forEach(item => {
      const date = new Date(item.date);
      const formattedDate = date.toISOString().split('T')[0]; // Format to yyyy-mm-dd
      formatted[formattedDate] = { status: item.status, homework: item.homework };
    });
    return formatted;
  };

  // Handle date selection in calendar
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Get status for the selected date for attendance
  const getStatusForDate = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formatAttendanceForCalendar()[formattedDate]?.status || null;
  };

  // Get homework status for the selected date
  const getHomeworkForDate = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formatAttendanceForCalendar()[formattedDate]?.homework || null;
  };

  // Apply styles based on attendance status for calendar tiles
  const tileClassNameForAttendance = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().split('T')[0];
      const status = formatAttendanceForCalendar()[formattedDate]?.status;

      if (status === 'Present') {
        return 'present';
      } else if (status === 'Absent') {
        return 'absent';
      }
    }
    return '';
  };

  // Apply styles based on homework status for calendar tiles
  const tileClassNameForHomework = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().split('T')[0];
      const homework = formatAttendanceForCalendar()[formattedDate]?.homework;

      if (homework === 'Yes') {
        return 'homework-yes';
      } else if (homework === 'No') {
        return 'homework-no';
      }
    }
    return '';
  };

  return (
    <Box className="dashboard-container">
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          <AlertTitle>{alertType === 'error' ? 'Error' : alertType === 'warning' ? 'Warning' : 'Success'}</AlertTitle>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Attendance Records
          </Typography>
          <TableContainer component={Paper} style={{ backgroundColor: '#333', color: '#fff', maxHeight: 400, overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Student Name</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Homework</TableHeaderCell>
                  <TableHeaderCell>Date</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceData.map((row) => (
                  <TableRow key={row.id}>
                    <TableBodyCell>{row.id}</TableBodyCell>
                    <TableBodyCell>{row.student_name}</TableBodyCell>
                    <TableBodyCell>{row.student_email}</TableBodyCell>
                    <TableBodyCell>{row.status}</TableBodyCell>
                    <TableBodyCell>{row.homework}</TableBodyCell>
                    <TableBodyCell>{new Date(row.date).toLocaleDateString()}</TableBodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={3}>
          <Grid item xs={12} md={12} className="chart-container">
            <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
              Attendance Calendar
            </Typography>
            <Calendar
              value={selectedDate}
              onChange={handleDateChange}
              tileClassName={tileClassNameForAttendance} // For attendance status
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <Grid item xs={12} md={12} className="chart-container">
            <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
              Homework Calendar
            </Typography>
            <Calendar
              value={selectedDate}
              onChange={handleDateChange}
              tileClassName={tileClassNameForHomework} // For homework status
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className="chart-container">
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Monthly Progress Line Graph
          </Typography>
          <Item className="dashboard-card">
            <LineGraph />
          </Item>
        </Grid>

        {/* <Grid item xs={12} md={6} className="chart-container">
            <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
              Monthly Attendance Bar
            </Typography>
            <Item className="dashboard-card">
              <StudentChart />
            </Item>
          </Grid> */}
      </Grid>
    </Box>
  );
}
