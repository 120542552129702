// import React from 'react';
// import { Route, Routes } from 'react-router-dom';
// import DashboardPage from '../pages/superadmin/DashboardPage';
// import SuperadminDetailPage from '../pages/superadmin/SuperAdminDetailPage';
// import SuperadminDetailEditPage from '../pages/superadmin/SuperAdminDetailEditPage';

// const SuperAdminRoutes = () => ( 
//         <Routes>
//             <Route path="/super-admin-dashboard" element={<DashboardPage />} />
//             <Route path="/super-admin-detail" element={<SuperadminDetailPage />} />
//             <Route path="/super-admin-detail-edit" element={<SuperadminDetailEditPage />} />
//         </Routes> 
// );

// export default SuperAdminRoutes;

import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DashboardPage from '../pages/superadmin/DashboardPage';
import SuperadminDetailPage from '../pages/superadmin/SuperAdminDetailPage';
import SuperadminDetailEditPage from '../pages/superadmin/SuperAdminDetailEditPage';
import { getAuthToken } from '../auth_token/authUtils';

const SuperAdminRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = () => {
      const token = getAuthToken();
      if (!token) { 
        navigate('/');
        window.location.reload();
      }
    };

    checkToken();
    const interval = setInterval(checkToken, 5000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <Routes>
      <Route path="/super-admin-dashboard" element={<DashboardPage />} />
      <Route path="/super-admin-detail" element={<SuperadminDetailPage />} />
      <Route path="/super-admin-detail-edit" element={<SuperadminDetailEditPage />} />
    </Routes>
  );
};

export default SuperAdminRoutes;
