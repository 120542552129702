import React, { useEffect, useState } from 'react';
import { getAuthToken } from '../../auth_token/authUtils';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Baseurl from '../../baseurl/baserurl';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const AttendanceTable = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        console.log('Fetching attendance data...');

        const user = JSON.parse(localStorage.getItem('user'));
        console.log('User data:', user);

        const school_id = user?.school_id;

        if (!school_id) {
          console.log('Missing School ID');
          setAlertMessage('School ID is missing.');
          setAlertType('error');
          setAlertOpen(true);
          return;
        }

        const token = getAuthToken();
        console.log('Token:', token);
        if (!token) {
          setAlertMessage('Sorry, you are not authenticated. Please re-login.');
          setAlertType('error');
          setAlertOpen(true);
          return;
        }

        const todayDate = getTodayDate();
        console.log('Today\'s Date:', todayDate);

        const response = await fetch(`${Baseurl.baseURL}api/admin/getallAttendenceforAdmin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            school_id,
            date: todayDate,
          }),
        });

        console.log('Response Status:', response.status);
        if (response.ok) {
          const data = await response.json();
          console.log('API Response Data:', data);
          if (data && data.data && Array.isArray(data.data)) {
            setAttendanceData(data.data);
          } else {
            console.log('Unexpected data format:', data);
            setAlertMessage('No data found or data is in an unexpected format.');
            setAlertType('warning');
            setAlertOpen(true);
          }
        } else {
          const error = await response.json();
          console.log('Error Response:', error);
          setAlertMessage('Error fetching attendance data: ' + error.message);
          setAlertType('error');
          setAlertOpen(true);
        }
      } catch (error) {
        console.log('Fetch Error:', error);
        setAlertMessage('An error occurred. Please try again.');
        setAlertType('error');
        setAlertOpen(true);
      }
    };

    fetchAttendanceData();

    const intervalId = setInterval(fetchAttendanceData, 50000);

    return () => clearInterval(intervalId);
  }, []);

  // Filter data to only show students who are absent
  const absentData = attendanceData.filter(item => item.status.toLowerCase() === 'absent');

  return (
    <div>
      {alertOpen && <Alert severity={alertType}>{alertMessage}</Alert>}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="attendance table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Student ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {absentData.map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>{item.student_id}</StyledTableCell>
                <StyledTableCell>{item.student_name}</StyledTableCell>
                <StyledTableCell>{item.status}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AttendanceTable;
