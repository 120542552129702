import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, Button } from '@mui/material';
import axios from 'axios';
import { getAuthToken } from '../../auth_token/authUtils';
import { styled } from '@mui/material/styles';
import Baseurl from '../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
}));

const WhiteTableBody = styled(TableBody)({
    backgroundColor: 'white',
});

const AddComplain = () => {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [openImage, setOpenImage] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { school_id, teacher_id, student_id } = user;
            fetchApplications(school_id, teacher_id, student_id);

            const intervalId = setInterval(() => {
                fetchApplications(school_id, teacher_id, student_id);
            }, 60000);

            return () => clearInterval(intervalId);
        } else {
            setError('User data not found. Please log in again.');
        }
    }, []);

    const fetchApplications = async (school_id, teacher_id, student_id) => {
        const authToken = getAuthToken();
        setError(null);
        setLoading(true);

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/addresult/getresultforstudentlogin`, {
                school_id,
                teacher_id,
                student_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.data.success) {
                const applicationsData = response.data.data || [];
                setApplications(applicationsData);
                setSuccess('Applications fetched successfully!');
            } else {
                setError('Failed to fetch applications.');
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleError = (error) => {
        if (error.response) {
            setError('Error fetching applications: ' + (error.response.data.message || 'Please try again.'));
        } else if (error.request) {
            setError('No response received. Please check your network connection.');
        } else {
            setError('Error: ' + error.message);
        }
    };

    const getFileType = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    const handleImageClick = (imageUrl) => {
        setOpenImage(imageUrl);
    };

    const handleClose = () => {
        setOpenImage(null);
    };

    return (
        <Box>
            <Box mt={4}>
                <Typography className="attendance-header" sx={{ margin: '5px 0px', fontSize: '20px', fontWeight: 'bold' }}>My Result List</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align='center'>My Result</StyledTableCell>
                                <StyledTableCell align='center'>Result Upload Date</StyledTableCell>
                                <StyledTableCell align='center'>Actions</StyledTableCell> {/* New column for actions */}
                            </TableRow>
                        </TableHead>
                        <WhiteTableBody>
                            {applications.length > 0 ? (
                                applications.map((app) => (
                                    <StyledTableRow key={app.id}>
                                        <StyledTableCell sx={{ background: 'white', color: 'black', textAlign: 'center' }}>
                                            {app.result ? (
                                                ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px', cursor: 'pointer' }}
                                                            onClick={() => handleImageClick(`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`)}
                                                        />
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View Result
                                                    </a>
                                                )
                                            ) : (
                                                'No Document'
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ background: 'white', color: 'black', textAlign: 'center' }}>{new Date(app.createdAt).toLocaleString()}</StyledTableCell>
                                        <StyledTableCell sx={{ background: 'white', color: 'black', textAlign: 'center' }}>
                                            {app.result && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                    download
                                                >
                                                    Download
                                                </Button>
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        No applications found.
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </WhiteTableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Modal to show enlarged image */}
            <Dialog open={Boolean(openImage)} onClose={handleClose} maxWidth="md">
                <DialogContent>
                    {openImage && (
                        <img
                            src={openImage}
                            alt="Enlarged Result"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* Notifications */}
            <Snackbar
                open={Boolean(error)}
                autoHideDuration={6000}
                onClose={() => setError(null)}
            >
                <Alert onClose={() => setError(null)} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={Boolean(success)}
                autoHideDuration={6000}
                onClose={() => setSuccess(null)}
            >
                <Alert onClose={() => setSuccess(null)} severity="success">
                    {success}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddComplain;
