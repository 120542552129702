import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { getAuthToken } from '../../auth_token/authUtils';
import Baseurl from '../../baseurl/baserurl';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export default function ResultTable() {
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [files, setFiles] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [studentResults, setStudentResults] = useState([]);

    useEffect(() => {
        fetchStudents();
    }, []);

    const fetchStudents = async () => {
        const token = getAuthToken();

        if (!token) {
            alert('Sorry Your are not authenticated. Please re-login.');
            return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;
        const teacher_id = user?.teacher_id;

        try {
            const response = await fetch(`${Baseurl.baseURL}api/addProfile/show_profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    school_id: school_id,
                    teacher_id: teacher_id,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                if (Array.isArray(data) && data.length > 0) {
                    setStudents(data);
                } else {
                    alert('No students found or data is in unexpected format.');
                }
            } else {
                const error = await response.json();
                alert('Error fetching students: ' + error.message);
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
        }
    };

    const handleFileChange = (e) => {
        setFiles((prevFiles) => ({
            ...prevFiles,
            [selectedStudent.student_id]: e.target.files[0],
        }));
    };

    const handleOpenDialog = (student) => {
        setSelectedStudent(student);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedStudent(null);
    };

    const handleSubmitResults = async () => {
        const token = getAuthToken();

        if (!token) {
            alert('Sorry Your are not authenticated. Please re-login.');
            return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();

        if (selectedStudent && files[selectedStudent.student_id]) {
            formData.append('school_id', user.school_id);
            formData.append('teacher_id', user.teacher_id);
            formData.append('student_id', selectedStudent.student_id);
            formData.append('result', files[selectedStudent.student_id]);
        } else {
            alert('Please upload a file.');
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(`${Baseurl.baseURL}api/addresult/addresult`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201) {
                alert('Results submitted successfully');
                setFiles({});
                handleCloseDialog();
            } else {
                alert('Error submitting results: ' + response.data.message);
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenPreviewDialog = async (student) => {
        setSelectedStudent(student);
        setIsLoading(true);
        try {
            const response = await axios.get(`${Baseurl.baseURL}api/addresult/student/${student.student_id}`, {
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            });

            if (response.status === 200) {
                setStudentResults(response.data);
                setOpenPreviewDialog(true);
            } else {
                alert('Error fetching student results');
            }
        } catch (error) {
            alert('An error occurred while fetching results');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClosePreviewDialog = () => {
        setOpenPreviewDialog(false);
        setStudentResults([]);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Student ID</StyledTableCell>
                            <StyledTableCell>Student Name</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                            <StyledTableCell>Result Preview</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {students.map((student) => (
                            <StyledTableRow key={student.student_id}>
                                <StyledTableCell>{student.student_id}</StyledTableCell>
                                <StyledTableCell>{student.student_name}</StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleOpenDialog(student)}
                                        disabled={isLoading}
                                    >
                                        Upload Result
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleOpenPreviewDialog(student)}
                                        disabled={isLoading}
                                        style={{ marginLeft: '8px' }}
                                    >
                                        Preview Result
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for file upload */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle sx={{color: 'black'}}>Upload Result for {selectedStudent?.student_name}</DialogTitle>
                <DialogContent>
                    <input
                        type="file"
                        accept=".pdf,.png,.jpeg,.jpg"
                        onChange={handleFileChange}
                        disabled={isLoading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary" disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitResults} color="primary" disabled={isLoading}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for previewing results */}
            <Dialog open={openPreviewDialog} onClose={handleClosePreviewDialog} fullWidth>
                <DialogTitle sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Results for {selectedStudent?.student_name}</DialogTitle>
                <DialogContent>
                    <div>
                        {studentResults.length > 0 ? (
                            <ul>
                                {studentResults
                                    .slice() // Create a copy of the array to avoid mutating the original
                                    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort in ascending order
                                    .reverse() // Reverse the sorted array to get the latest results first
                                    .map((result, index) => {
                                        // Use the createdAt field for date
                                        const parsedDate = new Date(result.createdAt);
                                        const formattedDate = parsedDate.toString() === "Invalid Date"
                                            ? "Date not available"
                                            : parsedDate.toLocaleString();

                                        // Determine file type
                                        const fileType = result.result.split('.').pop().toLowerCase();
                                        const isImage = ['png', 'jpeg', 'jpg'].includes(fileType);

                                        return (
                                            <div key={index} style={{ border: '2px solid black', margin: '15px', borderRadius: '10px' }}>
                                                <p style={{ textAlign: 'center' }}><strong>Date:</strong> {formattedDate}</p>
                                                <p style={{ textAlign: 'center' }}><strong>File:</strong>
                                                    {isImage ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img
                                                                src={`${Baseurl.baseURL}api/${result.result}`}
                                                                alt="Result Preview"
                                                                style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <a
                                                            href={`${Baseurl.baseURL}api/${result.result}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Result
                                                        </a>
                                                    )}
                                                </p>
                                            </div>
                                        );
                                    })}
                            </ul>
                        ) : (
                            <p>No results found.</p>
                        )}
                    </div>

                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClosePreviewDialog} color="secondary" disabled={isLoading}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
