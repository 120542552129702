import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography, Snackbar, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from '../../auth_token/authUtils';
import StudentChart from './Dashboard/barChart';
import StudentTable from './Dashboard/monthlyattendenceintable';
import StudentOfTheMonth from './Dashboard/studentofthemonth';
import StudentOfTheYear from './Dashboard/studentoftheyear';
import './css/dashboard.css';
import Baseurl from '../../baseurl/baserurl';

const Item = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '15px',
  textAlign: 'center',
  transition: 'transform 0.3s',
}));

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const getCurrentMonthName = () => {
  const today = new Date();
  return today.toLocaleString('default', { month: 'long' });
};

export default function BasicGrid() {
  const [present, setPresent] = useState(0);
  const [absent, setAbsent] = useState(0);
  const [total, setTotal] = useState(0);
  const [monthlyPresent, setMonthlyPresent] = useState(0);
  const [monthlyAbsent, setMonthlyAbsent] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');
  const alertTimeoutRef = useRef(null);
  const navigate = useNavigate();

  const currentMonthName = getCurrentMonthName();

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const { school_id, teacher_id, student_id } = user || {};

        if (!school_id || !teacher_id || !student_id) {
          setAlertMessage('School ID, Teacher ID, or Student ID is missing.');
          setAlertType('error');
          setAlertOpen(true);
          navigate('/');
          return;
        }

        const token = getAuthToken();
        if (!token) {
          setAlertMessage('Sorry, you are not authenticated. Please re-login.');
          setAlertType('error');
          setAlertOpen(true);
          navigate('/');
          return;
        }

        // Fetch daily attendance data
        const todayDate = getTodayDate();
        const dailyResponse = await fetch(`${Baseurl.baseURL}api/studentlogin/allAttendenceforstudentlogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            school_id,
            teacher_id,
            student_id,
            date: todayDate,
          }),
        });

        if (dailyResponse.ok) {
          const dailyData = await dailyResponse.json();
          if (dailyData?.data && Array.isArray(dailyData.data)) {
            const attendanceData = dailyData.data;

            const presentCount = attendanceData.filter(item => item.status === 'Present').length;
            const absentCount = attendanceData.filter(item => item.status === 'Absent').length;
            const totalCount = attendanceData.length;

            setPresent(presentCount);
            setAbsent(absentCount);
            setTotal(totalCount);
          } else {
            setAlertMessage('No data found or data is in an unexpected format.');
            setAlertType('warning');
            setAlertOpen(true);
          }
        } else {
          const error = await dailyResponse.json();
          setAlertMessage('Error fetching daily attendance data: ' + error.message);
          setAlertType('error');
          setAlertOpen(true);
        }

        // Fetch monthly attendance data
        const monthlyResponse = await fetch(`${Baseurl.baseURL}api/studentlogin/monthAttendencedataforstudentlogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            school_id,
            teacher_id,
            student_id,
          }),
        });

        if (monthlyResponse.ok) {
          const monthlyData = await monthlyResponse.json();
          if (monthlyData?.presentDays !== undefined && monthlyData?.absentDays !== undefined) {
            setMonthlyPresent(monthlyData.presentDays);
            setMonthlyAbsent(monthlyData.absentDays);
            // Assuming the total school days is the sum of present and absent days
            setMonthlyTotal(monthlyData.presentDays + monthlyData.absentDays);
          } else {
            setAlertMessage('No monthly data found.');
            setAlertType('warning');
            setAlertOpen(true);
          }
        } else {
          const error = await monthlyResponse.json();
          setAlertMessage('Error fetching monthly attendance data: ' + error.message);
          setAlertType('error');
          setAlertOpen(true);
        }
      } catch (error) {
        setAlertMessage('An error occurred. Please try again.');
        setAlertType('error');
        setAlertOpen(true);
      }
    };

    fetchAttendanceData();

    const intervalId = setInterval(fetchAttendanceData, 50000);

    return () => clearInterval(intervalId);
  }, [navigate]);


  useEffect(() => {
    if (alertOpen) {
      if (alertTimeoutRef.current) {
        clearTimeout(alertTimeoutRef.current);
      }
      alertTimeoutRef.current = setTimeout(() => {
        setAlertOpen(false);
      }, 6000); // Close the alert after 6 seconds
    }
  }, [alertOpen]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box className="dashboard-container">
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          <AlertTitle>{alertType === 'error' ? 'Error' : alertType === 'warning' ? 'Warning' : 'Success'}</AlertTitle>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Attendance Report
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Present Today
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>
                    {present > 0 ? 'Yes' : 'No'}
                  </Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Total Present in {currentMonthName}
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>
                    {monthlyPresent}
                  </Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Total Absent in {currentMonthName}
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>
                    {monthlyAbsent}
                  </Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Total School Days in {currentMonthName}
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>
                    {monthlyTotal}
                  </Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={12} className="chart-container">
              <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
                Monthly Attendance in Table
              </Typography>
              <Item className="dashboard-card">
                <StudentTable />
              </Item>
            </Grid>
            <Grid item xs={12} md={12} className="chart-container">
              <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
                Monthly Attendance Chart
              </Typography>
              <Item className="dashboard-card">
                <StudentChart />
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Current Month
          </Typography>
          <Item className="dashboard-card">
            <StudentOfTheMonth />
          </Item>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Year
          </Typography>
          <Item className="dashboard-card">
            <StudentOfTheYear />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
