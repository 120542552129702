// import React from 'react'
// import { BiNotification, BiSearch } from 'react-icons/bi'
// import "../../style/content.css"

// function contentHeader() {
//     return (
//         <div className="header">
//             <div className="content--header">
//                 <h1 className="header--title">Dashboard</h1>
//                 <div className="header--activity">
//                     <div className="notify">
//                         <BiNotification className='icon' />
//                     </div>
//                 </div>
//             </div>
//         </div>

//     )
// }

// export default contentHeader

import React, { useEffect, useState } from 'react';
import "../../../style/content.css";

function ContentHeader() {
    const [username, setUsername] = useState('');

    useEffect(() => {
        // Assuming the user's name is stored in localStorage under the key 'user'
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const user = JSON.parse(storedUser);
            setUsername(user.name || 'User'); // Default to 'User' if no name is found
        }
    }, []);

    return (
        <div className="header">
            <div className="content--header">
                <h1 className="header--title">Welcome, {username}</h1>
            </div>
        </div>
    );
}

export default ContentHeader;