import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from '../../auth_token/authUtils'; // Import utility functions
import { Box } from '@mui/material';
import Baseurl from '../../baseurl/baserurl';

const defaultTheme = createTheme();

const SignIn = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      // const response = await axios.post('https://app.negohtelbulletin.media/api/auth/login', {
      const response = await axios.post(`${Baseurl.baseURL}api/auth/login`, {
        email: data.get('email'),
        password: data.get('password'),
      });

      const { token, user } = response.data;

      if (token) {
        setAuthToken(token, user);

        if (user.role === 'student') {
          navigate('/student-dashboard');
        } else if (user.role === 'teacher') {
          navigate('/teacher-dashboard');
        } else if (user.role === 'admin') {
          navigate('/admin-dashboard');
        } else if (user.role === 'superadmin') {
          navigate('/super-admin-dashboard');
        } else {
          navigate('/'); // Default case if role is unrecognized
        }

        window.location.reload();
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" sx={{ height: '100%', padding: 0 }}>
        <CssBaseline />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundImage: 'url(/studee_logo.png)', // Replace with your background image path
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: 'transparent', // Add a slight overlay to darken the background
            animation: 'fadeIn 1s ease-in-out',
            padding: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: 2,
              boxShadow: 3,
              p: 4,
              width: '100%',
              maxWidth: '400px',
              animation: 'slideIn 0.5s ease-in-out',
              textAlign: 'center',
              position: 'relative', // Add relative positioning for the avatar
            }}
          >
            <Avatar
              sx={{
                m: 'auto', // Center horizontally
                bgcolor: 'secondary.main',
                width: 56,
                height: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute', // Absolute positioning within the box
                top: '-28px', // Adjust based on your layout
                left: '50%',
                transform: 'translateX(-50%)', // Center horizontally
              }}
            >
              <img
                src="/studee_logo.png" // Replace with your avatar image path
                alt="Avatar"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Welcome to Studee
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ mb: 2 }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ mb: 2 }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <style jsx global>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes slideIn {
          from {
            transform: translateY(-20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
    </ThemeProvider>
  );
}

export default SignIn;
