import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getAuthToken } from '../../../auth_token/authUtils';
import { Typography } from '@mui/material';
import Baseurl from '../../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export default function CustomizedTables() {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [schools, setSchools] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);

    // Fields for editing student information
    const [studentName, setStudentName] = useState('');
    const [studentEmail, setStudentEmail] = useState('');
    const [studentContact, setStudentContact] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [motherName, setMotherName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [currentClass, setCurrentClass] = useState('');
    const [studentPic, setStudentPic] = useState(null); // For file input
    const [batch, setBatch] = useState('');

    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState('success'); // success, error, warning
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleChangeforbatch = (e) => {
        const value = e.target.value;
        const numberValue = Number(value);

        // Validate and set the batch value
        if (value === '' || (numberValue >= 1 && numberValue <= 100)) {
            setBatch(value);
        }
    };

    useEffect(() => {
        fetchSchools();
    }, []);

    const fetchSchools = async () => {
        const token = getAuthToken();

        if (!token) {
            handleSnackbar('error', 'Sorry, you are not authenticated. Please re-login.');
            window.location.href = '/'; // Redirect to home page
            return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;
        const teacher_id = user?.teacher_id;

        try {
            const response = await fetch(`${Baseurl.baseURL}api/tutionstudentprofile/show_Tution_Student_profile_all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ school_id }),
            });

            if (response.ok) {
                const data = await response.json();
                setSchools(data);
            } else {
                const error = await response.json();
                handleSnackbar('error', 'Error fetching schools: ' + error.message);
            }
        } catch (error) {
            handleSnackbar('error', 'An error occurred. Please try again.');
        }
    };

    const handleEditClick = (student) => {
        setSelectedStudent(student);

        // Pre-fill the form with the selected student's data
        setStudentName(student.student_name || '');
        setStudentEmail(student.student_email || '');
        setStudentContact(student.student_contact || '');
        setFatherName(student.student_father_name || '');
        setMotherName(student.student_mother_name || '');
        setDateOfBirth(student.date_of_birth || '');
        setGender(student.gender || '');
        setCurrentClass(student.current_class || '');
        setBatch(student.batch); // Reset batch input
        setStudentPic(null); // Reset file input

        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleUpdateStudent = async () => {
        const token = getAuthToken();
        if (!token) {
            handleSnackbar('error', 'Sorry, you are not authenticated. Please re-login.');
            window.location.href = '/'; // Redirect to home page
            return;
        }

        const formData = new FormData();
        formData.append('student_name', studentName);
        formData.append('student_email', studentEmail);
        formData.append('student_contact', studentContact);
        formData.append('student_father_name', fatherName);
        formData.append('student_mother_name', motherName);
        formData.append('date_of_birth', dateOfBirth);
        formData.append('gender', gender);
        formData.append('current_class', currentClass);
        formData.append('batch', batch); // Ensure the batch value is correctly formatted

        if (studentPic) {
            formData.append('student_pic', studentPic); // Append the file
        }

        try {
            const response = await fetch(`${Baseurl.baseURL}api/tutionstudentprofile/update_profle_Tution_student/${selectedStudent.student_id}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData, // Send formData with the file
            });

            if (response.ok) {
                handleSnackbar('success', 'Student information updated successfully');
                fetchSchools(); // Refresh the table data
                handleCloseEditModal();
            } else {
                const error = await response.json();
                handleSnackbar('error', 'Error updating student: ' + error.message);
            }
        } catch (error) {
            handleSnackbar('error', 'An error occurred. Please try again.');
        }
    };

    const handleSnackbar = (type, message) => {
        setSnackbarType(type);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    // Get unique classes
    const uniqueClasses = [...new Set(schools.map(student => student.current_class))];

    return (
        <>
            {uniqueClasses.map((cls) => (
                <div key={cls} style={{ marginBottom: '20px' }}>
                    <Typography className="attendance-header" sx={{ margin: '5px 0px', fontSize: '20px', fontWeight: 'bold' }}>
                        Class {cls}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Edit</StyledTableCell>
                                    <StyledTableCell>Student Pic</StyledTableCell>
                                    <StyledTableCell>Student ID</StyledTableCell>
                                    <StyledTableCell>Student Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Contact No.</StyledTableCell>
                                    <StyledTableCell>Father Name</StyledTableCell>
                                    <StyledTableCell>Mother Name</StyledTableCell>
                                    <StyledTableCell>DOB</StyledTableCell>
                                    <StyledTableCell>Gender</StyledTableCell>
                                    <StyledTableCell>Class</StyledTableCell>
                                    <StyledTableCell>Batch</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {schools
                                    .filter(student => student.current_class === cls)
                                    .map((school) => (
                                        <StyledTableRow key={school.student_id}>
                                            <StyledTableCell>
                                                <Button variant="contained" color="primary" onClick={() => handleEditClick(school)}>
                                                    Edit
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {school.student_pic && (
                                                    <img
                                                        src={`${Baseurl.baseURL}api${school.student_pic}`}
                                                        alt="Student Pic"
                                                        width="50"
                                                        style={{ border: '1px solid black', borderRadius: '20px' }}
                                                    />
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell>{school.student_id}</StyledTableCell>
                                            <StyledTableCell>{school.student_name}</StyledTableCell>
                                            <StyledTableCell>{school.student_email}</StyledTableCell>
                                            <StyledTableCell>{school.student_contact}</StyledTableCell>
                                            <StyledTableCell>{school.student_father_name}</StyledTableCell>
                                            <StyledTableCell>{school.student_mother_name}</StyledTableCell>
                                            <StyledTableCell>{school.date_of_birth}</StyledTableCell>
                                            <StyledTableCell>{school.gender}</StyledTableCell>
                                            <StyledTableCell>{school.current_class}</StyledTableCell>
                                            <StyledTableCell>{school.batch ? `B-${school.batch}` : 'N/A'}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ))}

            <Dialog open={openEditModal} onClose={handleCloseEditModal}>
                <DialogTitle>Edit Student</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Student Name"
                        fullWidth
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Student Email"
                        fullWidth
                        value={studentEmail}
                        onChange={(e) => setStudentEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Contact No."
                        fullWidth
                        value={studentContact}
                        onChange={(e) => setStudentContact(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Father Name"
                        fullWidth
                        value={fatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Mother Name"
                        fullWidth
                        value={motherName}
                        onChange={(e) => setMotherName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Date of Birth"
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Gender"
                        fullWidth
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Class"
                        fullWidth
                        value={currentClass}
                        onChange={(e) => setCurrentClass(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="batch"
                        label="Batch (Enter only your batch Number)"
                        type="text"
                        fullWidth
                        value={batch}  // Display only the batch number
                        onChange={(e) => setBatch(e.target.value)}
                    />
                    <input
                        type="file"
                        onChange={(e) => setStudentPic(e.target.files[0])}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditModal}>Cancel</Button>
                    <Button onClick={handleUpdateStudent}>Update</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
