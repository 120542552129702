import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { getAuthToken } from '../../auth_token/authUtils';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import { Snackbar } from '@mui/material'; // Import Snackbar for alerts
import Baseurl from '../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
}));

const Loader = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export default function AttendanceTable() {
  const [schools, setSchools] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success'); // success, error, warning
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    fetchSchools();
    checkIfAlreadySubmitted();
  }, [date]);

  const fetchSchools = async () => {
    const token = getAuthToken();

    if (!token) {
      handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
      window.location.href = '/';
      window.location.reload();
      return;
    }

    const user = JSON.parse(localStorage.getItem('user'));
    const school_id = user?.school_id;
    const teacher_id = user?.teacher_id;

    try {
      const response = await fetch(`${Baseurl.baseURL}api/addProfile/show_profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_id: school_id,
          teacher_id: teacher_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setSchools(data);
        } else {
          handleAlert('warning', 'No data found or data is in unexpected format.');
        }
      } else {
        const error = await response.json();
        handleAlert('error', 'Error fetching schools: ' + error.message);
      }
    } catch (error) {
      handleAlert('error', 'An error occurred. Please try again.');
    }
  };

  const checkIfAlreadySubmitted = async () => {
    const token = getAuthToken();

    if (!token) {
      handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
      window.location.href = '/';
      window.location.reload();
      return;
    }

    const user = JSON.parse(localStorage.getItem('user'));

    try {
      const response = await fetch(`${Baseurl.baseURL}api/attendance/checkSubmission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_id: user.school_id,
          teacher_id: user.teacher_id,
          date: date,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setIsSubmitted(data.isSubmitted);
        handleAlert('success', 'All Student Show Successfully.');
      } else {
        const error = await response.json();
        handleAlert('error', 'Error checking submission status: ' + error.message);
      }
    } catch (error) {
      handleAlert('error', 'An error occurred. Please try again.');
    }
  };

  const handleAttendanceChange = (student_id, isPresent) => {
    setAttendance((prevAttendance) => ({
      ...prevAttendance,
      [student_id]: isPresent,
    }));
  };

  const handleSubmitAttendance = async () => {
    const token = getAuthToken();

    if (!token) {
      handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
      window.location.href = '/';
      window.location.reload();
      return;
    }

    const user = JSON.parse(localStorage.getItem('user'));

    const attendanceData = schools.map((student) => ({
      school_id: user.school_id,
      student_id: student.student_id,
      student_name: student.student_name,
      teacher_id: user.teacher_id,
      status: attendance[student.student_id] ? 'Present' : 'Absent',
      student_email: student.student_email
    }));

    setIsLoading(true); // Set loading state to true

    try {
      const response = await fetch(`${Baseurl.baseURL}api/attendance/submitAttendence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ attendanceData, date }),
      });

      if (response.ok) {
        handleAlert('success', 'Attendance submitted successfully');
        setAttendance({});
        setDate(new Date().toISOString().slice(0, 10));
        setSchools([]);
        setIsSubmitted(true);
      } else {
        const error = await response.json();
        handleAlert('error', 'Error submitting attendance: ' + error.message);
      }
    } catch (error) {
      handleAlert('error', 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false); // Set loading state to false after submission
    }
  };

  const handleAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <TextField
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Student ID</StyledTableCell>
              <StyledTableCell>Student Name</StyledTableCell>
              <StyledTableCell>Present</StyledTableCell>
              <StyledTableCell>Absent</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schools.map((student) => (
              <StyledTableRow key={student.student_id}>
                <StyledTableCell>{student.student_id}</StyledTableCell>
                <StyledTableCell>{student.student_name}</StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    checked={attendance[student.student_id] === true}
                    onChange={() => handleAttendanceChange(student.student_id, true)}
                    disabled={isSubmitted || isLoading}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    checked={attendance[student.student_id] === false}
                    onChange={() => handleAttendanceChange(student.student_id, false)}
                    disabled={isSubmitted || isLoading}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitAttendance}
          disabled={isSubmitted || isLoading} // Disable button if already submitted or loading
        >
          Submit Attendance
        </Button>
        {isLoading && <Loader size={20} />}
      </ButtonContainer>

      {/* Alert for success, error, or warning */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000} // Auto-hide after 6 seconds
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
