import React from 'react'
import { BiNotification, BiSearch } from 'react-icons/bi'
import "../../style/content.css"

function contentHeader() {
    return (
        <div className="header">
            <div className="content--header">
                <h1 className="header--title">Dashboard</h1>
                <div className="header--activity">
                    <div className="notify">
                        <BiNotification className='icon' />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default contentHeader