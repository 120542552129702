import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography, Snackbar, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from '../../auth_token/authUtils';
import './css/dashboard.css';
import StudentChart from './Dashboard/barChart';
import StudentOfTheMonth from './Dashboard/studentofthemonth';
import StudentOfTheYear from './Dashboard/studentoftheyear'; 
import Baseurl from '../../baseurl/baserurl';

const Item = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '15px',
  textAlign: 'center',
  transition: 'transform 0.3s',
}));

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default function BasicGrid() {
  const [present, setPresent] = useState(0);
  const [absent, setAbsent] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        console.log('Fetching attendance data...');

        // Get user data from localStorage
        const user = JSON.parse(localStorage.getItem('user'));
        console.log('User data:', user);

        const school_id = user?.school_id;
        // const teacher_id = user?.teacher_id;

        if (!school_id) {
          console.log('Missing School ID or Teacher ID');
          setAlertMessage('School ID or Teacher ID is missing.');
          setAlertType('error');
          setAlertOpen(true);
          return;
        }

        // Get auth token
        const token = getAuthToken();
        console.log('Token:', token);
        if (!token) {
          setAlertMessage('Sorry, you are not authenticated. Please re-login.');
          setAlertType('error');
          setAlertOpen(true);
          return;
        }

        const todayDate = getTodayDate();
        console.log('Today\'s Date:', todayDate);

        // Fetch attendance data from API
        const response = await fetch(`${Baseurl.baseURL}api/admin/getallAttendenceforAdmin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            school_id,
            date: todayDate,
          }),
        });

        console.log('Response Status:', response.status);
        if (response.ok) {
          const data = await response.json();
          console.log('API Response Data:', data);
          if (data && data.data && Array.isArray(data.data)) {
            const attendanceData = data.data;

            const presentCount = attendanceData.filter(item => item.status === 'Present').length;
            const absentCount = attendanceData.filter(item => item.status === 'Absent').length;
            const totalCount = attendanceData.length;

            setPresent(presentCount);
            setAbsent(absentCount);
            setTotal(totalCount);
          } else {
            console.log('Unexpected data format:', data);
            setAlertMessage('No data found or data is in an unexpected format.');
            setAlertType('warning');
            setAlertOpen(true);
          }
        } else {
          const error = await response.json();
          console.log('Error Response:', error);
          setAlertMessage('Error fetching attendance data: ' + error.message);
          setAlertType('error');
          setAlertOpen(true);
        }
      } catch (error) {
        console.log('Fetch Error:', error);
        setAlertMessage('An error occurred. Please try again.');
        setAlertType('error');
        setAlertOpen(true);
      }
    };

    fetchAttendanceData();

    const intervalId = setInterval(fetchAttendanceData, 50000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box className="dashboard-container">
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          <AlertTitle>{alertType === 'error' ? 'Error' : alertType === 'warning' ? 'Warning' : 'Success'}</AlertTitle>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Daily Attendance
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Today Present Students
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>{`${present}/${total}`}</Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Today Absent Students
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>{`${absent}/${total}`}</Typography>
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className="dashboard-card" sx={{ background: 'green', borderRadius: '20px' }}>
                <CardContent>
                  <Typography className="dashboard-title" sx={{ color: 'white' }}>
                    Total Students
                  </Typography>
                  <Typography className="dashboard-value" sx={{ fontSize: '30px', color: 'white' }}>{`${total}`}</Typography>
                </CardContent>
              </Item>
            </Grid>

            <Grid item xs={12} md={12} className="chart-container">
              <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
                Monthly Attendance Chart
              </Typography>
              <Item className="dashboard-card">
                <StudentChart />
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Student Of the Month
          </Typography>
          <Item className="dashboard-card">
            <StudentOfTheMonth />
          </Item>
          <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
            Student Of the Year
          </Typography>
          <Item className="dashboard-card">
            <StudentOfTheYear />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
