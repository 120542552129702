import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { getAuthToken } from '../../../auth_token/authUtils';
import Baseurl from '../../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export default function TeacherProfileTable() {
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [teachers, setTeachers] = React.useState([]);
    const [selectedTeacher, setSelectedTeacher] = React.useState(null);

    // const fetchTeachers = async () => {
    //     const token = getAuthToken();

    //     if (!token) {
    //         alert('Sorry, you are not authenticated. Please re-login.');
    //         window.location.href = '/';
    //         return;
    //     }

    //     const user = localStorage.getItem('user');
    //     if (!user) {
    //         alert('User information is missing. Please re-login.');
    //         window.location.href = '/';
    //         return;
    //     }

    //     const parsedUser = JSON.parse(user);
    //     const { school_id, student_id } = parsedUser;

    //     if (!school_id || !student_id) {
    //         alert('Missing school or student information. Please contact support.');
    //         return;
    //     }

    //     try {
    //         const response = await fetch(`${Baseurl.baseURL}api/student_profile/show_student_profile`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: JSON.stringify({
    //                 school_id,
    //                 student_id,
    //             }),
    //         });

    //         if (!response.ok) {
    //             throw new Error('Failed to fetch teachers');
    //         }

    //         const data = await response.json();
    //         const filteredData = data.filter((item) => item.role === 'admin');
    //         setTeachers(filteredData);
    //     } catch (error) {
    //         alert('An error occurred while fetching teachers: ' + error.message);
    //     }
    // };

    React.useEffect(() => {
        fetchTeachers(); // Initial fetch
        const intervalId = setInterval(fetchTeachers, 10000); // Fetch every 10 seconds
        return () => clearInterval(intervalId); // Clean up on component unmount
    }, []);


    const fetchTeachers = async () => {
        const token = getAuthToken();

        if (!token) {
            alert('Sorry, you are not authenticated. Please re-login.');
            window.location.href = '/';
            return;
        }

        const user = localStorage.getItem('user');
        if (!user) {
            alert('User information is missing. Please re-login.');
            window.location.href = '/';
            return;
        }

        const parsedUser = JSON.parse(user);
        const { school_id, student_id } = parsedUser;

        if (!school_id || !student_id) {
            alert('Missing school or student information. Please contact support.');
            return;
        }

        try {
            const response = await fetch(`${Baseurl.baseURL}api/student_profile/show_student_profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    school_id,
                    student_id,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch student profile');
            }

            const data = await response.json();

            // Assuming the API returns a single student object
            setTeachers([data]); // Wrap the single object in an array
        } catch (error) {
            alert('An error occurred while fetching student profile: ' + error.message);
        }
    };


    React.useEffect(() => {
        fetchTeachers(); // Initial fetch
        const intervalId = setInterval(fetchTeachers, 10000); // Fetch every 5 seconds
        return () => clearInterval(intervalId); // Clean up on component unmount
    }, []);

    const handleClose = () => setOpenEditModal(false);

    const handleEditOpen = (teacher) => {
        setSelectedTeacher(teacher);
        setName(teacher.name);
        setEmail(teacher.email);
        setPassword(teacher.password); // Clear the password field for security
        setOpenEditModal(true);
    };

    const handleEditSubmit = async () => {
        const token = getAuthToken();
        if (!token) {
            alert('Please re-login to continue.');
            window.location.href = '/';
            return;
        }

        try {
            const response = await fetch(`${Baseurl.baseURL}api/student_profile/update_student_profile/${selectedTeacher.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    name,
                    email,
                    password, // Password can be updated optionally
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update profile');
            }

            await fetchTeachers(); // Refresh the list after updating
            handleClose();
        } catch (error) {
            alert('An error occurred while updating profile: ' + error.message);
        }
    };

    return (
        <>
            <Dialog open={openEditModal} onClose={handleClose}>
                <DialogTitle>Edit Teacher Profile</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password (optional)"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Leave blank to keep the current password"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleEditSubmit} color="primary">Update</Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} style={{ maxHeight: 500, overflowY: 'auto' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Password</StyledTableCell>
                            <StyledTableCell>Edit</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teachers.map((teacher) => (
                            <StyledTableRow key={teacher.id}>
                                <StyledTableCell>{teacher.name}</StyledTableCell>
                                <StyledTableCell>{teacher.email}</StyledTableCell>
                                <StyledTableCell>{teacher.password}</StyledTableCell>
                                <StyledTableCell>
                                    <Button onClick={() => handleEditOpen(teacher)}>Edit</Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        </>
    );
}
