import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Snackbar, Alert, Dialog, DialogContent, IconButton } from '@mui/material';
import axios from 'axios';
import { getAuthToken } from '../../auth_token/authUtils';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import Baseurl from '../../baseurl/baserurl';

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AddApplicationForm = () => {
  const [credentials, setCredentials] = useState({
    school_id: '',
    teacher_id: '',
    student_id: '',
    name: ''
  });

  const [applications, setApplications] = useState([]);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const { school_id, teacher_id, student_id, name } = user;
      setCredentials({
        school_id,
        teacher_id,
        student_id,
        name
      });

      fetchStudentsAndApplications(school_id, teacher_id);

      const intervalId = setInterval(() => {
        fetchStudentsAndApplications(school_id, teacher_id);
      }, 5000);

      return () => clearInterval(intervalId);
    } else {
      setError('User data not found. Please log in again.');
    }
  }, []);

  const fetchStudentsAndApplications = async (school_id, teacher_id) => {
    const authToken = getAuthToken();

    setError(null);
    setLoading(true);

    try {
      const studentResponse = await axios.post(`${Baseurl.baseURL}api/addProfile/show_profile_for_admin_appliction1`,
        JSON.stringify({ school_id }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const filteredStudents = studentResponse.data.filter(student => student.school_id === school_id);
      setStudents(filteredStudents);

      const applicationResponse = await axios.post(`${Baseurl.baseURL}api/application/getapplicationforAdmin`,
        JSON.stringify({ school_id }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const applicationsWithClass = applicationResponse.data.map(app => {
        const matchingStudent = filteredStudents.find(student => student.teacher_id === app.teacher_id);
        return {
          ...app,
          current_class: matchingStudent ? matchingStudent.current_class : 'N/A',
        };
      });

      setApplications(applicationsWithClass);
      setSuccess('Applications and student data fetched successfully!');
    } catch (error) {
      if (error.response) {
        setError('Error fetching data: ' + error.response.data.message || 'Please try again.');
      } else if (error.request) {
        setError('No response received. Please check your network connection.');
      } else {
        setError('Error: ' + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getFileType = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const handleDocClick = (url) => {
    setDialogContent(url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent('');
  };

  const groupedApplications = applications.reduce((acc, app) => {
    const { teacher_id } = app;
    if (!acc[teacher_id]) {
      acc[teacher_id] = [];
    }
    acc[teacher_id].push(app);
    return acc;
  }, {});

  return (
    <Box>
      <Box>
        <Typography sx={{ margin: '10px 0px', textAlign: 'center', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>
          Applications List by Teacher
        </Typography>
        {Object.keys(groupedApplications).map((teacher_id) => (
          <Box key={teacher_id} mb={4}>
            <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
              Class Teacher ID: {teacher_id}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Teacher ID</StyledTableCell>
                    <StyledTableCell align='center'>Name</StyledTableCell>
                    <StyledTableCell align='center'>Subject</StyledTableCell>
                    <StyledTableCell align='center'>Description</StyledTableCell>
                    <StyledTableCell align='center'>Doc</StyledTableCell>
                    <StyledTableCell align='center'>Created At</StyledTableCell>
                    <StyledTableCell align='center'>Class</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedApplications[teacher_id].map((app) => (
                    <StyledTableRow key={app.id}>
                      <StyledTableCell>{app.teacher_id}</StyledTableCell>
                      <StyledTableCell align='center'>{app.name}</StyledTableCell>
                      <StyledTableCell align='center'>{app.subject}</StyledTableCell>
                      <StyledTableCell align='center'>{app.describe}</StyledTableCell>
                      <StyledTableCell align='center'>
                        {app.result ? (
                          ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                            <div style={{ textAlign: 'center' }}>
                              <img
                                src={`${Baseurl.baseURL}api/${app.result}`}
                                alt="Result Preview"
                                style={{ maxWidth: '50px', maxHeight: '50px', cursor: 'pointer' }}
                                onClick={() => handleDocClick(`${Baseurl.baseURL}api/${app.result}`)}
                              />
                            </div>
                          ) : (
                            <a
                              href={`${Baseurl.baseURL}api/${app.result}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Result
                            </a>
                          )
                        ) : (
                          'No Document'
                        )}
                      </StyledTableCell>
                      <StyledTableCell align='center'>{new Date(app.createdAt).toLocaleString()}</StyledTableCell>
                      <StyledTableCell align='center'>{app.current_class}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Box>
      {loading && <CircularProgress />}
      {error && (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
          <Alert onClose={() => setSuccess(null)} severity="success">
            {success}
          </Alert>
        </Snackbar>
      )}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogContent>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            style={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {['png', 'jpeg', 'jpg'].includes(getFileType(dialogContent)) ? (
            <img
              src={dialogContent}
              alt="Document"
              style={{ width: '100%', height: 'auto' }}
            />
          ) : (
            <a href={dialogContent} target="_blank" rel="noopener noreferrer">
              View Document
            </a>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddApplicationForm;
