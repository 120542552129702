
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiHome, BiBookAlt, BiMessage, BiSolidReport } from 'react-icons/bi';
import { Button } from '@mui/material';
import "../style/sidebar.css";

function Sidebar() {
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/');
            }
        };

        // Initial check
        checkToken();

        // Set up the interval to check every 5 seconds (5000 ms)
        const interval = setInterval(checkToken, 5000);

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [navigate]);

    // const handleLogout = (event) => {
    //     event.preventDefault(); // Prevent default button behavior

    //     // Clear authentication data
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('tokenExpiration');
    //     localStorage.removeItem('user');
    //     console.log("Logging out and redirecting...");
    //     // Optional: If using a state management library, update state here

    //     // Redirect to login page
    //     navigate('/');
    //     window.location.href = '/'; // Redirect to home page
    //     window.location.reload(); // Force reload the page
    // };
    const handleLogout = async (event) => {
        if (event) event.preventDefault();
        try {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
            localStorage.removeItem('user');
            // setIsOpen(false);

            // Redirect to login page
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <div className="menu">
            <div className="logo">
                {/* <BiBookAlt className='logo-icon' /> */}
                <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
                <h2>Studee</h2>
            </div>
            <div className="menu--list">
                <Link to="/super-admin-dashboard" className="item">
                    <BiHome className='icon' />
                    Dashboard
                </Link>
                <Link to="/super-admin-detail" className="item">
                    <BiMessage className='icon' />
                    All School Detail
                </Link>
                <Link to="/super-admin-detail-edit" className="item">
                    <BiSolidReport className='icon' />
                    School Detail Edit
                </Link>
            </div>
            <div className="logout">
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            </div>
        </div>
    );
}

export default Sidebar;


// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { BiHome, BiBookAlt, BiMessage, BiSolidReport, BiMenu, BiX } from 'react-icons/bi';
// import { FiLogOut } from "react-icons/fi";
// import { Button } from '@mui/material';
// import "../style/sidebar.css";

// function Sidebar() {
//     const [isOpen, setIsOpen] = useState(true);
//     const navigate = useNavigate();

//     const handleLogout = (event) => {
//         event.preventDefault(); // Prevent default button behavior

//         // Clear authentication data
//         localStorage.removeItem('token');
//         localStorage.removeItem('tokenExpiration');
//         localStorage.removeItem('user');
//         console.log("Logging out and redirecting...");
//         // Optional: If using a state management library, update state here

//         // Redirect to login page
//         navigate('/');
//         window.location.reload();
//     };

//     const toggleSidebar = () => {
//         setIsOpen(!isOpen);
//     };

//     return (
//         <div className={`menu ${isOpen ? 'open' : 'closed'}`}>
//             <div className="logo">
//                 {/* <BiBookAlt className='logo-icon' /> */}
//                 {isOpen && <h2>SMS</h2>}
//                 <button className="menu-toggle" onClick={toggleSidebar}>
//                     {isOpen ? <BiX className='icon' /> : <BiMenu className='icon' />}
//                 </button>
//             </div>
//             <div className="menu-content">
//                 <div className="menu--list">
//                     <Link to="/super-admin-dashboard" className="item">
//                         <BiHome className='icon' />
//                         {isOpen && <span>Dashboard</span>}
//                     </Link>
//                     <Link to="/super-admin-detail" className="item">
//                         <BiMessage className='icon' />
//                         {isOpen && <span>All School Detail</span>}
//                     </Link>
//                     <Link to="/super-admin-detail-edit" className="item">
//                         <BiSolidReport className='icon' />
//                         {isOpen && <span>School Detail Edit</span>}
//                     </Link>
//                 </div>
//             </div>
//             <div className="logout">
//                 <Button
//                     variant="contained"
//                     color="error"
//                     onClick={handleLogout}
//                 >
//                     <FiLogOut />
//                 </Button>
//             </div>
//         </div>
//     );
// }

// export default Sidebar;
