
import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Snackbar, Alert, Grid, TextField, Button } from '@mui/material';
import axios from 'axios';
import { getAuthToken } from '../../auth_token/authUtils';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Baseurl from '../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AddApplicationForm = () => {
    const [formData, setFormData] = useState({
        subject: '',
        describe: '',
    });

    const [file, setFile] = useState(null);
    const [credentials, setCredentials] = useState({
        school_id: '',
        teacher_id: '',
        student_id: '',
        name: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { school_id, teacher_id, student_id, name } = user;
            setCredentials({ school_id, teacher_id, student_id, name });

            fetchApplications(school_id, teacher_id, student_id);
            const intervalId = setInterval(() => {
                fetchApplications(school_id, teacher_id, student_id);
            }, 5000);

            return () => clearInterval(intervalId);
        } else {
            setError('User data not found. Please log in again.');
        }
    }, []);

    const fetchApplications = async (school_id, teacher_id, student_id) => {
        const authToken = getAuthToken();
        setError(null);
        setLoading(true);

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/studentloginapplication/getapplicationforstudentlogin`,
                JSON.stringify({
                    school_id,
                    teacher_id,
                    student_id,
                }), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                // Extract the application array from the response
                const { application } = response.data;
                setApplications(application);
                setSuccess('Applications fetched successfully!');
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            if (error.response) {
                setError('Error fetching applications: ' + (error.response.data.message || 'Please try again.'));
            } else if (error.request) {
                setError('No response received. Please check your network connection.');
            } else {
                setError('Error: ' + error.message);
            }
        } finally {
            setLoading(false);
        }
    };



    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        const formDataToSend = new FormData();
        formDataToSend.append('school_id', credentials.school_id);
        formDataToSend.append('teacher_id', credentials.teacher_id);
        formDataToSend.append('student_id', credentials.student_id);
        formDataToSend.append('name', credentials.name);
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('describe', formData.describe);

        if (file) {
            formDataToSend.append('result', file);
        }

        const authToken = getAuthToken();

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/studentloginapplication/applicationforstudentlogin`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setSuccess(response.data.message || 'Application added successfully!');
                fetchApplications(credentials.school_id, credentials.teacher_id, credentials.student_id);
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    // Display specific error message for 400 status code
                    setError(error.response.data.message || 'Bad request. Please check your input.');
                } else {
                    setError(error.response.data.message || 'Error adding application. Please try again.');
                }
            } else if (error.request) {
                setError('No response received. Please check your network connection.');
            } else {
                setError('Error: ' + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const getFileType = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    return (
        <Box>
            {/* Form Section */}
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
                <Typography variant="h6" gutterBottom>
                    Add Application
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Subject"
                                name="subject"
                                fullWidth
                                value={formData.subject}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Describe"
                                name="describe"
                                fullWidth
                                value={formData.describe}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button component="label">
                                Upload Doc.
                                <TextField type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                endIcon={loading && <CircularProgress size={24} />}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                {/* Snackbar for success or error messages */}
                <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
                    <Alert onClose={() => setSuccess(null)} severity="success">
                        {success}
                    </Alert>
                </Snackbar>

                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert onClose={() => setError(null)} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </Paper>

            <Box>
                <Box style={{ marginTop: '20px' }}>
                    <Typography variant="h6" gutterBottom>
                        Applications List
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Student ID</StyledTableCell>
                                    <StyledTableCell>Student Name</StyledTableCell>
                                    <StyledTableCell>Subject</StyledTableCell>
                                    <StyledTableCell>Description</StyledTableCell>
                                    <StyledTableCell>Doc</StyledTableCell>
                                    <StyledTableCell>Created At</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applications.length > 0 ? (
                                    applications.map((app) => (
                                        <StyledTableRow key={app.id}>
                                            <StyledTableCell>{app.student_id}</StyledTableCell>
                                            <StyledTableCell>{app.name}</StyledTableCell>
                                            <StyledTableCell>{app.subject}</StyledTableCell>
                                            <StyledTableCell>{app.describe}</StyledTableCell>
                                            <StyledTableCell>
                                                {app.result ? (
                                                    ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img
                                                                src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                                alt="Result Preview"
                                                                style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <a
                                                            href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Result
                                                        </a>
                                                    )
                                                ) : (
                                                    'No Document'
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell>{new Date(app.createdAt).toLocaleString()}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={6} style={{ textAlign: 'center' }}>
                                            No applications available
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {loading && <CircularProgress style={{ display: 'block', margin: '20px auto' }} />}
                {error && (
                    <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                        <Alert onClose={() => setError(null)} severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                )}
                {success && (
                    <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
                        <Alert onClose={() => setSuccess(null)} severity="success">
                            {success}
                        </Alert>
                    </Snackbar>
                )}
            </Box>
        </Box>
    );
};

export default AddApplicationForm;
