import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import axios from 'axios';
import { getAuthToken } from '../../../auth_token/authUtils';
import { styled } from '@mui/material/styles';
import Baseurl from '../../../baseurl/baserurl';
import ReactQuill from 'react-quill';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.MuiTableCell-head`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.MuiTableCell-body`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const parseHtmlDescribe = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const paragraphs = Array.from(doc.querySelectorAll('p'));

    const separatorRegex = /[\-\s\→\=\|<>]+/;

    return paragraphs.map(p => {
        const [subject, date, teacher_date] = p.textContent.split(separatorRegex).map(s => s.trim());
        return { subject, date, teacher_date };
    });
};

const AddComplain = () => {
    const [applications, setApplications] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [selectedDescribe, setSelectedDescribe] = useState('');
    const [editableSubjects, setEditableSubjects] = useState([]);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { school_id, student_id } = user;
            fetchApplications(school_id, student_id);
        }
    }, []);

    const fetchApplications = async (school_id, student_id) => {
        const authToken = getAuthToken();
        setError(null);

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/datesheet/getdatesheetall`, {
                school_id,
                student_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                const { application } = response.data;
                setApplications(application);
                setSuccess('Applications fetched successfully!');
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            setError('Error fetching applications: ' + (error.response?.data?.message || 'Please try again.'));
        }
    };

    const groupedApplications = applications.reduce((groups, app) => {
        const { current_class } = app;
        if (!groups[current_class]) {
            groups[current_class] = [];
        }
        groups[current_class].push(app);
        return groups;
    }, {});

    const handleOpenEditDialog = (describe, id) => {
        const parsedDescribe = parseHtmlDescribe(describe);
        setEditableSubjects(parsedDescribe);
        setSelectedDescribe(describe);
        setSelectedId(id);
        setOpenEditDialog(true);
    };

    const handleOpenViewDialog = (describe) => {
        setSelectedDescribe(describe);
        setOpenViewDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedDescribe('');
        setSelectedId(null);
    };

    const handleCloseViewDialog = () => {
        setOpenViewDialog(false);
        setSelectedDescribe('');
    };

    const handleInputChange = (index, field, value) => {
        const updatedSubjects = [...editableSubjects];
        updatedSubjects[index][field] = value;
        setEditableSubjects(updatedSubjects);
    };

    const handleUpdate = async () => {
        const authToken = getAuthToken();

        try {
            const response = await axios.put(`${Baseurl.baseURL}api/tution_teacher_get_current_class_batch/updatedatesheet`, {
                describe: selectedDescribe,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                params: { id: selectedId },
            });

            if (response.status === 200) {
                setSuccess('Date Sheet updated successfully!');
                setOpenEditDialog(false);
            } else {
                setError('Error updating Date Sheet');
            }
        } catch (err) {
            setError('Error updating Date Sheet: ' + (err.response?.data?.message || 'Please try again.'));
        }
    };

    const getFileType = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    return (
        <>
            <Box>
                {Object.keys(groupedApplications).map((current_class) => (
                    <Box mt={4} key={current_class}>
                        <Typography className="attendance-header" sx={{ margin: '5px 0px' }}>
                            Exam Date Sheet List for Class {current_class}
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Student ID</StyledTableCell>
                                        <StyledTableCell>Student Name</StyledTableCell>
                                        <StyledTableCell>Date Sheet</StyledTableCell>
                                        <StyledTableCell>File</StyledTableCell>
                                        <StyledTableCell>Class</StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groupedApplications[current_class].map((app) => (
                                        <StyledTableRow key={app.id}>
                                            <StyledTableCell>{app.student_id}</StyledTableCell>
                                            <StyledTableCell>{app.name}</StyledTableCell>
                                            <StyledTableCell>
                                                <Button onClick={() => handleOpenViewDialog(app.describe)}>
                                                    View DateSheet
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {app.result ? (
                                                    ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                        />
                                                    ) : (
                                                        <a
                                                            href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Result
                                                        </a>
                                                    )
                                                ) : (
                                                    'No Document'
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell>{app.current_class}</StyledTableCell>
                                            <StyledTableCell>{new Date(app.createdAt).toLocaleString()}</StyledTableCell>
                                            <StyledTableCell>
                                                <Button onClick={() => handleOpenEditDialog(app.describe, app.id)} variant="outlined">
                                                    Edit
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                ))}

                <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert onClose={() => setError(null)} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                <Snackbar open={Boolean(success)} autoHideDuration={6000} onClose={() => setSuccess(null)}>
                    <Alert onClose={() => setSuccess(null)} severity="success">
                        {success}
                    </Alert>
                </Snackbar>

                <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth>
                    <DialogTitle sx={{ color: 'black' }}>Update Exam Date Sheet</DialogTitle>
                    <DialogContent>
                        <ReactQuill
                            value={selectedDescribe}
                            onChange={setSelectedDescribe}
                            theme="snow"
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ size: [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                    ['link', 'image', 'video'],
                                    ['clean']
                                ],
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpdate} color="primary" variant="contained">
                            Update
                        </Button>
                        <Button onClick={handleCloseEditDialog} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openViewDialog} onClose={handleCloseViewDialog} fullWidth>
                    <DialogTitle sx={{ color: 'black' }}>Exam Date Sheet Details</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Subject</StyledTableCell>
                                    <StyledTableCell>Exam Date</StyledTableCell>
                                    <StyledTableCell>Prepration Date</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parseHtmlDescribe(selectedDescribe).map((entry, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell>{entry.subject}</StyledTableCell>
                                        <StyledTableCell>{entry.date}</StyledTableCell>
                                        <StyledTableCell>{entry.teacher_date}</StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseViewDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export default AddComplain;
