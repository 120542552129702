import React from 'react'
import ContentHeader from './contentHeader'
import "../../style/content.css"

function content() {
  return (
    <ContentHeader />
  )
}

export default content