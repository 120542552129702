import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../../pages/tution/Teacher/DashboardTeacher';
import AllStudentDatail from '../../pages/tution/Teacher/allstudent';
import Add_edit_student from '../../pages/tution/Teacher/addeditstudent';
import AllClasses from '../../pages/tution/Teacher/myclasses';
import AllDatesheets from '../../pages/tution/Teacher/mydatesheets';
import AllSyllabus from '../../pages/tution/Teacher/mysyllabus';

const TeacherRoutes = () => (
    <Routes>
        <Route path="/tution_teacher-dashboard" element={<DashboardPage />} />
        <Route path="/tution-all-student-detail" element={<AllStudentDatail />} />
        <Route path="/tution_teacher-add-edit-student" element={<Add_edit_student />} />
        <Route path="/tution-all-class-detail" element={<AllClasses />} />
        <Route path="/tution-all-datesheet-detail" element={<AllDatesheets />} />
        <Route path="/tution-all-syllabus-detail" element={<AllSyllabus />} />

    </Routes>
);

export default TeacherRoutes;