
// import React, { useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { BiHome, BiBookAlt, BiMessage, BiSolidReport } from 'react-icons/bi';
// import { Button } from '@mui/material';
// import "../../style/sidebar.css";

// function Sidebar() {
//     const navigate = useNavigate();

//     useEffect(() => {
//         const checkToken = () => {
//             const token = localStorage.getItem('token');
//             if (!token) {
//                 navigate('/');
//             }
//         };

//         // Initial check
//         checkToken();

//         // Set up the interval to check every 5 seconds (5000 ms)
//         const interval = setInterval(checkToken, 5000);

//         // Clear the interval when the component unmounts
//         return () => clearInterval(interval);
//     }, [navigate]);

//     const handleLogout = (event) => {
//         event.preventDefault(); // Prevent default button behavior

//         // Clear authentication data
//         localStorage.removeItem('token');
//         localStorage.removeItem('tokenExpiration');
//         localStorage.removeItem('user');
//         console.log("Logging out and redirecting...");
//         // Optional: If using a state management library, update state here

//         // Redirect to login page
//         navigate('/');
//         window.location.href = '/'; // Redirect to home page
//         window.location.reload(); // Force reload the page
//     };

//     return (
//         <div className="menu">
//             <div className="logo">
//                 {/* <BiBookAlt className='logo-icon' /> */}
//                 <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
//                 <h2>Studee</h2>
//             </div>
//             <div className="menu--list">
// <Link to="/tution_student-dashboard" className="item">
//     <BiHome className='icon' />
//     Dashboard
// </Link>
// <Link to="/tution_student-detail" className="item">
//     <BiMessage className='icon' />
//     Exam Date-Sheet
// </Link>
// <Link to="/tution_student-profile" className="item">
//     <BiSolidReport className='icon' />
//     My Profile
// </Link>
//             </div>
//             <div className="logout">
//                 <Button
//                     variant="contained"
//                     color="error"
//                     onClick={handleLogout}
//                 >
//                     Logout
//                 </Button>
//             </div>
//         </div>
//     );
// }

// export default Sidebar;




// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { BiHome, BiBookAlt, BiMessage, BiSolidReport, BiMenu, BiX } from 'react-icons/bi';
// import { Button } from '@mui/material';
// import "../../style/sidebar.css";

// function Sidebar() {
//     const [isOpen, setIsOpen] = useState(false);
//     const navigate = useNavigate(); 

//     const handleLogout = async (event) => {
//         event.preventDefault();
//         try { 
//             localStorage.removeItem('token');
//             localStorage.removeItem('tokenExpiration');
//             localStorage.removeItem('user');
//             setIsOpen(false);
//             setTimeout(() => {
//                 window.location.href = '/';
//             }, 2000);
//         } catch (error) {
//             console.error('Logout error:', error); 
//         }
//     };



//     const toggleSidebar = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleLinkClick = () => {
//         setIsOpen(false); // Close sidebar after link click
//     };

//     return (
//         <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
//             <div className="toggle-btn" onClick={toggleSidebar}>
//                 {isOpen ? <BiX size={30} /> : <BiMenu size={30} />}
//             </div>
//             {isOpen && (
//                 <div className="menu">
//                     <div className="logo">
//                         {/* <BiBookAlt className='logo-icon' /> */}
//                         <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
//                         <h2>Studee</h2>
//                     </div>
//                     <div className="menu--list">
//                         <Link to="/tution_student-dashboard" className="item" onClick={handleLinkClick}>
//                             <BiHome className='icon' />
//                             Dashboard
//                         </Link>
//                         <Link to="/tution_student-detail" className="item" onClick={handleLinkClick}>
//                             <BiMessage className='icon' />
//                             Exam Date-Sheet
//                         </Link>
//                         <Link to="/tution_student-profile" className="item" onClick={handleLinkClick}>
//                             <BiSolidReport className='icon' />
//                             My Profile
//                         </Link>
//                         {/* <Link to="/tution-all-syllabus-detail" className="item" onClick={handleLinkClick}>
//                             <BiMessage className='icon' />
//                             Syllabus
//                         </Link>
//                         <Link to="/tution-all-student-detail" className="item" onClick={handleLinkClick}>
//                             <BiMessage className='icon' />
//                             All Student Detail
//                         </Link>
//                         <Link to="/tution_teacher-add-edit-student" className="item" onClick={handleLinkClick}>
//                             <BiSolidReport className='icon' />
//                             Add & Edit Student profile
//                         </Link> */}
//                     </div>
//                     <div className="logout">
//                         <Button
//                             variant="contained"
//                             color="error"
//                             onClick={handleLogout}
//                         >
//                             Logout
//                         </Button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default Sidebar;



import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiHome, BiMessage, BiSolidReport, BiMenu, BiX } from 'react-icons/bi';
import { Button } from '@mui/material';
import "../../style/sidebar.css";

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/');
            }
        }; 
        checkToken(); 
        const interval = setInterval(checkToken, 5000); 
        return () => clearInterval(interval);
    }, [navigate]);

    const handleLogout = async (event) => {
        if (event) event.preventDefault();
        try {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
            localStorage.removeItem('user');
            setIsOpen(false);
            
            // Redirect to login page
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false); // Close sidebar after link click
    };

    useEffect(() => {
        // Function to check token
        const checkToken = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                handleLogout();
            }
        };

        // Check token every 5 seconds
        const intervalId = setInterval(checkToken, 5000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
            <div className="toggle-btn" onClick={toggleSidebar}>
                {isOpen ? <BiX size={30} /> : <BiMenu size={30} />}
            </div>
            {isOpen && (
                <div className="menu">
                    <div className="logo">
                        <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
                        <h2>Studee</h2>
                    </div>
                    <div className="menu--list">
                        <Link to="/tution_student-dashboard" className="item" onClick={handleLinkClick}>
                            <BiHome className='icon' />
                            Dashboard
                        </Link>
                        <Link to="/tution_student-detail" className="item" onClick={handleLinkClick}>
                            <BiMessage className='icon' />
                            Exam Date-Sheet
                        </Link>
                        <Link to="/tution_student-syllabus-detail" className="item" onClick={handleLinkClick}>
                            <BiMessage className='icon' />
                            Syllabus
                        </Link>
                        <Link to="/tution_student-profile" className="item" onClick={handleLinkClick}>
                            <BiSolidReport className='icon' />
                            My Profile
                        </Link>
                        {/* Additional Links */}
                    </div>
                    <div className="logout">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Sidebar;
