import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Snackbar, Alert, Grid, TextField, Button } from '@mui/material';
import axios from 'axios';
import { getAuthToken } from '../../auth_token/authUtils';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Baseurl from '../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AddComplain = () => {
    const [formData, setFormData] = useState({
        subject: '',
        describe: '',
    });

    const [file, setFile] = useState(null);
    const [credentials, setCredentials] = useState({
        school_id: '',
        teacher_id: '',
        student_id: '',
        name: '',
        current_class: '' // Ensure consistency with the server-side and state
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { school_id, teacher_id, student_id, name } = user;
            setCredentials((prev) => ({ ...prev, school_id, teacher_id, student_id, name }));

            fetchApplications(school_id, teacher_id, student_id);
            fetchClassIfMatches(school_id, teacher_id, student_id);

            const intervalId = setInterval(() => {
                fetchApplications(school_id, teacher_id, student_id);
            }, 60000);

            return () => clearInterval(intervalId);
        } else {
            setError('User data not found. Please log in again.');
        }
    }, []);

    const fetchApplications = async (school_id, teacher_id, student_id) => {
        const authToken = getAuthToken();
        setError(null);
        setLoading(true);

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/compalint/getcomplain`, {
                school_id,
                teacher_id,
                student_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                const { application } = response.data;
                setApplications(application);
                setSuccess('Applications fetched successfully!');
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchClassIfMatches = async (school_id, teacher_id, student_id) => {
        const authToken = getAuthToken();

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/studentlogin/show_profile_student_login`, {
                school_id,
                teacher_id,
                student_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                const profiles = response.data;
                console.log("Profile data:", profiles);  // Debugging line

                if (Array.isArray(profiles) && profiles.length > 0) {
                    const profile = profiles[0]; // Get the first item in the array

                    if (profile.school_id === school_id && profile.teacher_id === teacher_id && profile.student_id === student_id) {
                        setCredentials((prev) => ({
                            ...prev,
                            current_class: profile.current_class || 'No class found'
                        }));
                    } else {
                        console.error('Profile data does not match the credentials');
                    }
                } else {
                    console.error('Profile data is not an array or is empty');
                }
            }
        } catch (error) {
            console.error('Error fetching class:', error);
        }
    };


    const handleError = (error) => {
        if (error.response) {
            setError('Error fetching applications: ' + (error.response.data.message || 'Please try again.'));
        } else if (error.request) {
            setError('No response received. Please check your network connection.');
        } else {
            setError('Error: ' + error.message);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        const formDataToSend = new FormData();
        formDataToSend.append('school_id', credentials.school_id);
        formDataToSend.append('teacher_id', credentials.teacher_id);
        formDataToSend.append('student_id', credentials.student_id);
        formDataToSend.append('name', credentials.name);
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('describe', formData.describe);

        formDataToSend.append('current_class', credentials.current_class);
        // if (credentials.current_class) {
        // }

        if (file) {
            formDataToSend.append('result', file);
        }

        const authToken = getAuthToken();

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/compalint/appcomplain`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setSuccess(response.data.message || 'Application added successfully!');
                fetchApplications(credentials.school_id, credentials.teacher_id, credentials.student_id);
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };


    const getFileType = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    return (
        <Box>
            {/* Form Section */}
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
                <Typography variant="h6" gutterBottom>
                    Add Complain
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Subject"
                                name="subject"
                                fullWidth
                                value={formData.subject}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Describe"
                                name="describe"
                                fullWidth
                                value={formData.describe}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button component="label">
                                Upload Doc.
                                <TextField type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            {/* Display Applications */}
            <Box mt={4}>
                <Typography variant="h6">Complain List</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Student ID</StyledTableCell>
                                <StyledTableCell>Student Name</StyledTableCell>
                                <StyledTableCell>Subject</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell>Doc</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applications.length > 0 ? (
                                applications.map((app) => (
                                    <StyledTableRow key={app.id}>
                                        <StyledTableCell>{app.student_id}</StyledTableCell>
                                        <StyledTableCell>{app.name}</StyledTableCell>
                                        <StyledTableCell>{app.subject}</StyledTableCell>
                                        <StyledTableCell>{app.describe}</StyledTableCell>
                                        <StyledTableCell>
                                            {app.result ? (
                                                ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View Result
                                                    </a>
                                                )
                                            ) : (
                                                'No Document'
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell>{new Date(app.createdAt).toLocaleString()}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={6} align="center">
                                        No applications found.
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Notifications */}
            <Snackbar
                open={Boolean(error)}
                autoHideDuration={6000}
                onClose={() => setError(null)}
            >
                <Alert onClose={() => setError(null)} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={Boolean(success)}
                autoHideDuration={6000}
                onClose={() => setSuccess(null)}
            >
                <Alert onClose={() => setSuccess(null)} severity="success">
                    {success}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddComplain;
