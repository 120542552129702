import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../pages/student/DashboardPage';
// import SchoolDetailPage from '../pages/student/StudentDetailPage';
import Application from '../pages/student/myapplication';
import MyProfile from '../pages/student/profile';
import Mycomplains from '../pages/student/mycomplains';
import Myfeedbacks from '../pages/student/myfeedbacks';
import MyResult from '../pages/student/myresult';

const StudentRoutes = () => ( 
        <Routes>
            <Route path="/student-dashboard" element={<DashboardPage />} /> 
            <Route path="/student-detail-edit" element={<Application />} />
            <Route path="/student-profile" element={<MyProfile />} />
            <Route path="/student-complains" element={<Mycomplains />} />
            <Route path="/student-feedbacks" element={<Myfeedbacks />} />
            <Route path="/student-result" element={<MyResult />} />
        </Routes> 
);

export default StudentRoutes;
