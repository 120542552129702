
import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Snackbar, Alert, Grid, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getAuthToken } from '../../../auth_token/authUtils';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Baseurl from '../../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        ['link'],
        ['clean']
    ],
};

const formats = [
    'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'clean'
];

const parseHtmlDescribe = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const paragraphs = Array.from(doc.querySelectorAll('p'));

    // Define a regular expression to match various separators
    const separatorRegex = /[\-\s\→\=\|<>]+/;

    return paragraphs.map(p => {
        // Use the regular expression to split text content
        const [subject, date, teacher_date] = p.textContent.split(separatorRegex).map(s => s.trim());
        return { subject, date, teacher_date };
    });
};


const AddComplain = () => {
    const [formData, setFormData] = useState({
        subject: '',
        describe: '',
    });

    const [file, setFile] = useState(null);
    const [credentials, setCredentials] = useState({
        school_id: '',
        student_id: '',
        name: '',
        current_class: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [applications, setApplications] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDescribe, setSelectedDescribe] = useState('');
    const [fileToDisplay, setFileToDisplay] = useState(null);


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            const { school_id, student_id, name } = user;

            if (school_id && student_id && name) {
                setCredentials((prev) => ({ ...prev, school_id, student_id, name }));

                const fetchData = async () => {
                    try {
                        await fetchApplications(school_id, student_id);
                        await fetchClassIfMatches(school_id, student_id);
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        setError('Failed to fetch applications or class data.');
                    }
                };

                fetchData();

                const intervalId = setInterval(() => {
                    fetchData();
                }, 60000);

                return () => clearInterval(intervalId);
            } else {
                setError('Invalid user data. Please log in again.');
            }
        } else {
            setError('User data not found. Please log in again.');
        }
    }, []);


    const fetchApplications = async (school_id, student_id) => {
        const authToken = getAuthToken();
        setError(null);
        setLoading(true);

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/syllabus/getsyllabus`, {
                school_id,
                student_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                const { application } = response.data;
                setApplications(application);
                setSuccess('Applications fetched successfully!');
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchClassIfMatches = async (school_id, student_id) => {
        const authToken = getAuthToken();

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/tution_teacher_get_current_class_batch/getdatesheetall`, {
                school_id,
                student_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                const profiles = response.data;
                console.log("Profile data:", profiles);

                if (Array.isArray(profiles) && profiles.length > 0) {
                    const profile = profiles[0];

                    if (profile.school_id === school_id && profile.student_id === student_id) {
                        // Set the current class and other profile details
                        setCredentials((prev) => ({
                            ...prev,
                            current_class: profile.current_class || 'No class found',
                        }));
                    } else {
                        console.error('Profile data does not match the credentials');
                    }
                } else {
                    console.error('Profile data is not an array or is empty');
                }
            }
        } catch (error) {
            console.error('Error fetching class:', error);
        }
    };


    const handleError = (error) => {
        if (error.response) {
            setError('Error fetching applications: ' + (error.response.data.message || 'Please try again.'));
        } else if (error.request) {
            setError('No response received. Please check your network connection.');
        } else {
            setError('Error: ' + error.message);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleQuillChange = (value) => {
        setFormData({ ...formData, describe: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        // Verify current_class value before appending
        console.log('Submitting with current_class:', credentials.current_class);

        const formDataToSend = new FormData();
        formDataToSend.append('school_id', credentials.school_id);
        formDataToSend.append('student_id', credentials.student_id);
        formDataToSend.append('name', credentials.name);
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('describe', formData.describe);
        formDataToSend.append('current_class', credentials.current_class); // Make sure this is included

        if (file) {
            formDataToSend.append('result', file);
        }

        const authToken = getAuthToken();

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/syllabus/addsyllabus`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setSuccess(response.data.message || 'Application added successfully!');
                fetchApplications(credentials.school_id, credentials.student_id);
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };



    const getFileType = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    const handleOpenDialog = (describe) => {
        setSelectedDescribe(describe);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedDescribe('');
    };

    return (
        <Box>
            <Paper elevation={3} style={{ padding: '30px', marginBottom: '30px', borderRadius: '8px' }}>
                <Typography variant="h6" style={{ marginBottom: '20px' }}>Add Syllabus</Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <TextField
                        label="Notice"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                    />
                    <ReactQuill
                        value={formData.describe}
                        onChange={handleQuillChange}
                        modules={modules}
                        formats={formats}
                        style={{ height: '200px', marginBottom: '20px' }}
                    />
                    <TextField
                        accept="application/pdf, application/msword, image/*"
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        style={{ marginBottom: '20px' }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        style={{ alignSelf: 'flex-start' }}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </form>
            </Paper>

            {/* Display Applications */}
            <Box mt={4}>
                <Typography variant="h6">Syllabus List</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Student ID</StyledTableCell>
                                <StyledTableCell>Student Name</StyledTableCell>
                                <StyledTableCell>Notice</StyledTableCell>
                                <StyledTableCell>Syllabus</StyledTableCell>
                                <StyledTableCell>File</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                                {/* <StyledTableCell>Actions</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applications.length > 0 ? (
                                applications.map((app) => (
                                    <StyledTableRow key={app.id}>
                                        <StyledTableCell>{app.student_id}</StyledTableCell>
                                        <StyledTableCell>{app.name}</StyledTableCell>
                                        <StyledTableCell>{app.subject}</StyledTableCell>
                                        <StyledTableCell>
                                            <Button onClick={() => handleOpenDialog(app.describe)}>
                                                View Syllabus
                                            </Button>
                                        </StyledTableCell>
                                        {/* <StyledTableCell>
                                            {app.result ? (
                                                ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View Result
                                                    </a>
                                                )
                                            ) : (
                                                'No Document'
                                            )}
                                        </StyledTableCell> */}
                                        <StyledTableCell>
                                            {app.result ? (
                                                ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                    <div style={{ cursor: 'pointer' }} onClick={() => setFileToDisplay(`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`)}>
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View Result
                                                    </a>
                                                )
                                            ) : (
                                                'No Document'
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell>{new Date(app.createdAt).toLocaleString()}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={7} align="center">
                                        No applications found.
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Notifications */}
            <Snackbar
                open={Boolean(error)}
                autoHideDuration={6000}
                onClose={() => setError(null)}
            >
                <Alert onClose={() => setError(null)} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={Boolean(success)}
                autoHideDuration={6000}
                onClose={() => setSuccess(null)}
            >
                <Alert onClose={() => setSuccess(null)} severity="success">
                    {success}
                </Alert>
            </Snackbar>

            {/* Dialog for Exam Date Sheet Details */}
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
                <DialogTitle sx={{ color: 'black' }}>Exam Date Sheet Details</DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: selectedDescribe }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={Boolean(fileToDisplay)} onClose={() => setFileToDisplay(null)} fullScreen>
                <DialogContent>
                    {fileToDisplay && (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {['png', 'jpeg', 'jpg'].includes(getFileType(fileToDisplay)) ? (
                                <img
                                    src={fileToDisplay}
                                    alt="Full-Screen File"
                                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                />
                            ) : (
                                <iframe
                                    src={fileToDisplay}
                                    style={{ width: '100%', height: '100%' }}
                                    title="Document Viewer"
                                />
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setFileToDisplay(null)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default AddComplain;
