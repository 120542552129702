import React, { useState, useEffect } from 'react';
import { getAuthToken } from '../../../auth_token/authUtils';
import { Box, Typography, Paper, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';
import Baseurl from '../../../baseurl/baserurl';

// Register chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function StudentOfTheMonth() {
    const [attendanceData, setAttendanceData] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    useEffect(() => {
        const fetchAttendanceData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const { school_id, teacher_id, student_id } = user || {};

                if (!school_id || !teacher_id || !student_id) {
                    handleSnackbar('School ID, Teacher ID, or Student ID is missing.', 'warning');
                    return;
                }

                const token = getAuthToken();
                if (!token) {
                    handleSnackbar('Sorry, you are not authenticated. Please re-login.', 'error');
                    window.location.href = '/'; // Redirect to home page
                    window.location.reload(); // Force reload the page
                    return;
                }

                // Fetch data from the studentOfTheMonth API
                const response = await fetch(`${Baseurl.baseURL}api/studentlogin/currentmonthdataforstudentlogin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        school_id,
                        teacher_id,
                        student_id,
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setAttendanceData(data.data); // Store the attendance data
                } else {
                    const error = await response.json();
                    handleSnackbar('Error fetching attendance data: ' + error.message, 'error');
                }
            } catch (error) {
                handleSnackbar('An error occurred. Please try again.', 'error');
            }
        };

        fetchAttendanceData();
        const intervalId = setInterval(fetchAttendanceData, 50000);

        return () => clearInterval(intervalId);
    }, []);

    const handleSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: 'info' });
    };

    // Prepare data for the line chart
    const labels = []; // Dates in the month
    const presentData = []; // Present count for each date
    const absentData = []; // Absent count for each date

    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().format('YYYY-MM');

    // Initialize the labels with the days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
        const date = moment().date(i).format('YYYY-MM-DD');
        labels.push(date);
        presentData.push(0); // Initialize all days as 0
        absentData.push(0);  // Initialize all days as 0
    }

    // Populate the present and absent counts based on the fetched data
    attendanceData.forEach((record) => {
        const recordDate = moment(record.date).format('YYYY-MM-DD');
        const dayIndex = labels.indexOf(recordDate);

        if (dayIndex !== -1) {
            if (record.status === 'Present') {
                presentData[dayIndex] += 1;
            } else if (record.status === 'Absent') {
                absentData[dayIndex] += 1;
            }
        }
    });




    // Prepare data for the table
    const tableRows = labels.map((date, index) => {
        return {
            date,
            present: presentData[index],
            absent: absentData[index],
            total: presentData[index] + absentData[index],
        };
    });

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Student Attendance Growth for the Month
            </Typography>
            <Paper>
                <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: 'auto' }}>
                    <Table   aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell >Date</StyledTableCell>
                                <StyledTableCell align="center">Present</StyledTableCell>
                                <StyledTableCell align="center">Absent</StyledTableCell>
                                <StyledTableCell align="center">Total</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        {moment(row.date).format('DD')}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.present}</StyledTableCell>
                                    <StyledTableCell align="center">{row.absent}</StyledTableCell>
                                    <StyledTableCell align="center">{row.total}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
