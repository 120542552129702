import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { getAuthToken } from '../../../auth_token/authUtils';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Baseurl from '../../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));


export default function CustomizedTables() {
    const [open, setOpen] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [school_Name, setSchoolName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [mobile, setSchoolContact] = useState('');
    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);

    useEffect(() => {
        fetchSchools(); // Initial fetch

        const intervalId = setInterval(() => {
            fetchSchools(); // Fetch every 10 seconds
        }, 10000); // 10000 ms = 10 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const fetchSchools = async () => {
        const token = getAuthToken();

        if (!token) {
            alert('Sorry Your are not authenticated. Please re-login.');
            window.location.href = '/'; // Redirect to home page
            window.location.reload(); // Force reload the page
            return;
        }


        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;

        try {
            const response = await fetch(`${Baseurl.baseURL}api/addNewTeacher/show_teachers`, {
                method: 'POST', // Changed from GET to POST
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    school_id: school_id,
                }),
            });

            if (response.ok) {
                // const data = await response.json();
                // setSchools(data);
                const data = await response.json();
                const filteredData = data.filter((item) => item.role === 'teacher');
                setSchools(filteredData);
            } else {
                const error = await response.json();
                alert('Error fetching schools: ' + error.message);
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEditModal(false); // Close the edit modal as well
    };



    const handleEditOpen = (school) => {
        setSelectedSchool(school);
        setSchoolName(school.name);
        setEmail(school.email);
        setPassword(school.password);
        setSchoolContact(school.mobile);
        setRole(school.role);
        setOpenEditModal(true);
    };

    const handleEditSubmit = async () => {
        const token = getAuthToken();

        if (!token) {
            alert('Sorry Your are not authenticated. Please re-login.');
            window.location.href = '/'; // Redirect to home page
            window.location.reload(); // Force reload the page
            return;
        }


        try {
            const response = await fetch(`${Baseurl.baseURL}api/addNewTeacher/teachers/${selectedSchool.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    school_Name: school_Name,
                    email: email,
                    password: password,
                    mobile: mobile,
                    role: role,
                }),
            });

            if (response.ok) {
                fetchSchools(); // Refresh the table after editing
                handleClose();
            } else {
                const error = await response.json();
                alert('Error updating school: ' + error.message);
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
        }
    };

    const handleDelete = async (id) => {
        const token = getAuthToken();

        if (!token) {
            alert('Sorry Your are not authenticated. Please re-login.');
            window.location.href = '/'; // Redirect to home page
            window.location.reload(); // Force reload the page
            return;
        }


        try {
            const response = await fetch(`${Baseurl.baseURL}api/addNewTeacher/teachers/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                fetchSchools(); // Refresh the list after deletion
            } else {
                alert('Error deleting school.');
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Dialog open={openEditModal} onClose={handleClose}>
                <DialogTitle sx={{ color: 'black', textAlign: 'center' }}>Edit User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="schoolName"
                        label="Name"
                        type="text"
                        fullWidth
                        value={school_Name}
                        onChange={(e) => setSchoolName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="schoolContact"
                        label="Teacher Contact"
                        type="text"
                        fullWidth
                        value={mobile}
                        onChange={(e) => setSchoolContact(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Role</InputLabel>
                        <Select value={role} onChange={(e) => setRole(e.target.value)}>
                            {/* <MenuItem value="admin">Admin</MenuItem> */}
                            {/* <MenuItem value="student">Student</MenuItem> */}
                            <MenuItem value="teacher">Teacher</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleEditSubmit} color="primary">Update</Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} style={{ maxHeight: 500, overflowY: 'auto' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell>ID</StyledTableCell> */}
                            <StyledTableCell>Unique ID</StyledTableCell>
                            <StyledTableCell>School ID</StyledTableCell>
                            <StyledTableCell>Teacher ID</StyledTableCell>
                            <StyledTableCell>Student ID</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            {/* <StyledTableCell>School Name</StyledTableCell> */}
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Password</StyledTableCell>
                            <StyledTableCell>Teacher Contact</StyledTableCell>
                            <StyledTableCell>Role</StyledTableCell>
                            <StyledTableCell>Role ID</StyledTableCell>
                            <StyledTableCell>Edit</StyledTableCell>
                            <StyledTableCell>Delete</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {schools.map((school) => (
                            <StyledTableRow key={school.id}>
                                {/* <StyledTableCell component="th" scope="row">
                  {school.id}
                </StyledTableCell> */}
                                <StyledTableCell>{school.unique_id}</StyledTableCell>
                                <StyledTableCell>{school.school_id}</StyledTableCell>
                                <StyledTableCell>{school.teacher_id}</StyledTableCell>
                                <StyledTableCell>{school.student_id}</StyledTableCell>
                                <StyledTableCell>{school.name}</StyledTableCell>
                                {/* <StyledTableCell>{school.school_name}</StyledTableCell> */}
                                <StyledTableCell>{school.email}</StyledTableCell>
                                <StyledTableCell>{school.password}</StyledTableCell>
                                <StyledTableCell>{school.teacher_contact}</StyledTableCell>
                                <StyledTableCell>{school.role}</StyledTableCell>
                                <StyledTableCell>{school.role_id}</StyledTableCell>
                                <StyledTableCell>
                                    <Button onClick={() => handleEditOpen(school)}>Edit</Button>
                                </StyledTableCell>
                                {/* <StyledTableCell>
                  <IconButton aria-label="delete" onClick={() => handleDelete(school.id)}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell> */}
                                <StyledTableCell>
                                    {school.role === 'admin' ? (
                                        '----' // Show '----' if role is superadmin
                                    ) : (
                                        <IconButton aria-label="delete" onClick={() => handleDelete(school.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

