
// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { BiHome, BiBookAlt, BiMessage, BiSolidReport } from 'react-icons/bi';
// import { Button } from '@mui/material';
// import "../style/sidebar.css";

// function Sidebar() {
//     const navigate = useNavigate();

//     const handleLogout = (event) => {
//         event.preventDefault(); // Prevent default button behavior

//         // Clear authentication data
//         localStorage.removeItem('token');
//         localStorage.removeItem('tokenExpiration');
//         localStorage.removeItem('user');
//         console.log("Logging out and redirecting...");
//         // Optional: If using a state management library, update state here

//         // Redirect to login page
//         navigate('/');
//         window.location.reload();
//     };

//     return (
//         <div className="menu">
//             <div className="logo">
//                 <BiBookAlt className='logo-icon' />
//                 <h2>SMS</h2>
//             </div>
// <div className="menu--list">
//     <Link to="/student-dashboard" className="item">
//         <BiHome className='icon' />
//         Dashboard
//     </Link>
//     <Link to="/student-detail" className="item">
//         <BiMessage className='icon' />
//         Student Detail
//     </Link>
//     <Link to="/student-detail-edit" className="item">
//         <BiSolidReport className='icon' />
//         Student Detail Edit
//     </Link>
// </div>
//             <div className="logout">
//                 <Button
//                     variant="contained"
//                     color="error"
//                     onClick={handleLogout}
//                 >
//                     Logout
//                 </Button>
//             </div>
//         </div>
//     );
// }

// export default Sidebar;




import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiHome, BiBookAlt, BiMessage, BiSolidReport, BiMenu, BiX } from 'react-icons/bi';
import { Button } from '@mui/material';
import "../style/sidebar.css";

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    // const handleLogout = (event) => {
    //     event.preventDefault();
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('tokenExpiration');
    //     localStorage.removeItem('user');
    //     navigate('/');
    //     window.location.reload();
    // };
    const handleLogout = async (event) => {
        if (event) event.preventDefault();
        try {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
            localStorage.removeItem('user');
            setIsOpen(false);

            // Redirect to login page
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false); // Close sidebar after link click
    };

    return (
        <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
            <div className="toggle-btn" onClick={toggleSidebar}>
                {isOpen ? <BiX size={30} /> : <BiMenu size={30} />}
            </div>
            {isOpen && (
                <div className="menu">
                    <div className="logo">
                        {/* <BiBookAlt className='logo-icon' /> */}
                        <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
                        <h2>Studee</h2>
                    </div>
                    <div className="menu--list">
                        <div className="menu--list">
                            <Link to="/student-dashboard" className="item" onClick={handleLinkClick}>
                                <BiHome className='icon' />
                                Dashboard
                            </Link>
                            <Link to="/student-detail-edit" className="item" onClick={handleLinkClick}>
                                <BiSolidReport className='icon' />
                                My Application
                            </Link>
                            <Link to="/student-complains" className="item" onClick={handleLinkClick}>
                                <BiMessage className='icon' />
                                My Complains
                            </Link>
                            <Link to="/student-feedbacks" className="item" onClick={handleLinkClick}>
                                <BiMessage className='icon' />
                                My Feedbacks
                            </Link>
                            <Link to="/student-result" className="item" onClick={handleLinkClick}>
                                <BiSolidReport className='icon' />
                                My Result
                            </Link>
                            <Link to="/student-profile" className="item" onClick={handleLinkClick}>
                                <BiSolidReport className='icon' />
                                My Profile
                            </Link>
                        </div>
                    </div>
                    <div className="logout">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Sidebar;
