import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAuthToken } from '../../../auth_token/authUtils';
import Baseurl from '../../../baseurl/baserurl';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export default function CustomizedTables() {
    const [open, setOpen] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [school_Name, setSchoolName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [role, setRole] = React.useState('');
    const [mobile, setSchoolContact] = React.useState('');
    const [schools, setSchools] = React.useState([]);
    const [selectedSchool, setSelectedSchool] = React.useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState('success'); // success, error, warning
    const [snackbarMessage, setSnackbarMessage] = useState('');

    React.useEffect(() => {
        fetchSchools();
    }, []);

    const fetchSchools = async () => {
        const token = getAuthToken();

        if (!token) {
            handleSnackbar('error', 'Sorry, you are not authenticated. Please re-login.');
            window.location.href = '/'; // Redirect to home page
            return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;
        const teacher_id = user?.teacher_id;
        const student_id = user?.student_id;

        try {
            const response = await fetch(`${Baseurl.baseURL}api/studentlogin/show_profile_student_login_user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ school_id, teacher_id, student_id }),
            });

            if (response.ok) {
                const data = await response.json();
                setSchools(data);
            } else {
                const error = await response.json();
                handleSnackbar('error', 'Error fetching schools: ' + error.message);
            }
        } catch (error) {
            handleSnackbar('error', 'An error occurred. Please try again.');
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEditModal(false); // Close the edit modal as well
    };


    const handleEditOpen = (school) => {
        setSelectedSchool(school);
        setSchoolName(school.school_name);
        setEmail(school.email);
        setPassword(school.password);
        setSchoolContact(school.mobile);
        setRole(school.role);
        setOpenEditModal(true);
    };

    const handleEditSubmit = async () => {
        const token = getAuthToken();

        if (!token) {
            alert('Sorry Your are not authenticated. Please re-login.');
            window.location.href = '/'; // Redirect to home page
            window.location.reload(); // Force reload the page
            return;
        }


        try {
            const response = await fetch(`${Baseurl.baseURL}api/studentlogin/schools/${selectedSchool.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ 
                    password: password, 
                }),
            });

            if (response.ok) {
                fetchSchools(); // Refresh the table after editing
                handleClose();
            } else {
                const error = await response.json();
                alert('Error updating school: ' + error.message);
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
        }
    };

    const handleSnackbar = (type, message) => {
        setSnackbarType(type);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>

            <Dialog open={openEditModal} onClose={handleClose}>
                <DialogTitle>Edit School</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleEditSubmit} color="primary">Update Profile</Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow> 
                            <StyledTableCell sx={{textAlign: 'left'}}>Teacher ID</StyledTableCell>
                            <StyledTableCell sx={{textAlign: 'left'}}>Student ID</StyledTableCell>
                            <StyledTableCell sx={{textAlign: 'left'}}>Student Name</StyledTableCell> 
                            <StyledTableCell sx={{textAlign: 'left'}}>Email</StyledTableCell>
                            <StyledTableCell sx={{textAlign: 'left'}}>Password</StyledTableCell> 
                            <StyledTableCell sx={{textAlign: 'left'}}>Role</StyledTableCell> 
                            <StyledTableCell sx={{textAlign: 'left'}}>Edit</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {schools.map((school) => (
                            <StyledTableRow key={school.id}> 
                                <StyledTableCell sx={{textAlign: 'left'}}>{school.teacher_id}</StyledTableCell>
                                <StyledTableCell sx={{textAlign: 'left'}}>{school.student_id}</StyledTableCell>
                                <StyledTableCell sx={{textAlign: 'left'}}>{school.name}</StyledTableCell> 
                                <StyledTableCell sx={{textAlign: 'left'}}>{school.email}</StyledTableCell>
                                <StyledTableCell sx={{textAlign: 'left'}}>{school.password}</StyledTableCell> 
                                <StyledTableCell sx={{textAlign: 'left'}}>{school.role}</StyledTableCell> 
                                <StyledTableCell sx={{textAlign: 'left'}}>
                                    <Button onClick={() => handleEditOpen(school)} variant="contained" color="primary">Edit</Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
